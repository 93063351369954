import { isEqual, forEach, has, get, isNil } from 'lodash';
import moment from 'moment-timezone';

import { ERROR_CODES, ERROR_MESSAGES, REQUEST_TYPE } from 'appConstants';
import { useTenant } from 'hooks';

export const defaultValuesForDiff = {
  images: [],
  og_image: null
};

export const useLookupTableValue = () => {
  const tenant = useTenant();

  const isLookupTableAllowed = ['btpn', 'btpn_preproduction', 'btpn_production', 'ocbc'].includes(
    tenant?.name
  );

  return isLookupTableAllowed;
};

export const getFormattedDateTime = (values: { date: string; time: string }): string | null => {
  return values.date && values.time
    ? moment(`${values.date} ${values.time}`, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
      )
    : null;
};

/**
 * Parses the error message from an error object.
 *
 * @param error - The error object which may contain a response with a data message.
 * @param fallBackErrorSms - An optional fallback error message to use if the error object does not contain a message.
 * @returns The parsed error message, the fallback error message if provided, or a default message 'Something went wrong'.
 */
export const parseErrrorMessage = (error: any, fallBackErrorSms?: string): string => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  } else if (fallBackErrorSms) {
    return fallBackErrorSms;
  } else {
    return 'Something went wrong';
  }
};

/**
 * Get differences between current and payload objects.
 * @param {Record<string, any>} current - The base object to compare against.
 * @param {Record<string, any>} payload - The object containing potential updates.
 * @returns {Record<string, any>} - An object containing only the keys from the payload that are new or different from the current object.
 */
export const getDiff = (
  current: Record<string, any>,
  payload: Record<string, any>
): Record<string, any> => {
  // Initialize an empty object to store the differences
  const diff: Record<string, any> = {};

  // Iterate over the keys in the payload object
  forEach(payload, (value: any, key: string) => {
    // Check if the key exists in the current object and if the values are different
    if (!has(current, key) || !isEqual(value, get(current, key))) {
      // If the key is new or the value is different, add it to the diff object
      diff[key] = value;
    }
  });

  // Return the object containing only the differences
  return diff;
};

/**
 * Generate card header style based on changed fields.
 * @param {Set<string> | undefined} changedFields - The set of changed fields.
 * @param {string[]} attributes - The list of attributes to check for changes.
 * @param {string} prefix - The prefix to check for specific changes (e.g., 'event_params.' or 'outcome_options.').
 * @param {React.CSSProperties} defaultStyle - The default style to apply if no changes are detected.
 * @param {React.CSSProperties} changedStyle - The style to apply if changes are detected.
 * @returns {React.CSSProperties} - The style object for the card header.
 */
export const getCardHeadStyle = (
  changedFields: Set<string> | undefined,
  attributes: string[],
  prefix: string,
  defaultStyle: React.CSSProperties,
  changedStyle: React.CSSProperties
): React.CSSProperties => {
  const hasChangedFields =
    changedFields &&
    (attributes.some(field => changedFields.has(field)) ||
      (prefix && Array.from(changedFields || []).some(field => field.startsWith(prefix))));

  return hasChangedFields ? changedStyle : defaultStyle;
};

type ErrorCodeKeys = keyof typeof ERROR_CODES;

export const getErrorMessage = (code: ErrorCodeKeys): string =>
  ERROR_CODES[code] || 'Something went wrong';

type ErrorMessageKeys = keyof typeof ERROR_MESSAGES;

export const getFormattedErrorMessage = (message: ErrorMessageKeys): string =>
  ERROR_MESSAGES[message] || 'Something went wrong';

/**
 * Sets the value of the specified key to null if the key does not exist or its value is 0.
 *
 * @param {Record<string, any>} obj - The object to check and modify.
 * @param {string} key - The key to check in the object.
 * @returns {void} - This function does not return a value.
 */
export const setNullIfZero = (obj: Record<string, any>, key: string): void => {
  const value = get(obj, key);
  if (isNil(value) || value === 0) {
    obj[key] = null;
  }
};

/**
 * Generates a formatted string based on the request type and category.
 *
 * @param {string} [request_type] - The type of the request (e.g., "create").
 * @param {string} [request_category] - The category of the request entity (e.g., "Rewards", "Stamps").
 * @returns {string} A formatted string combining `category` and `type`.
 */
export const formatRequestType = (request_type?: string, request_category?: string): string => {
  const normalizedCategory = (request_category || '').toLowerCase();
  const normalizedRequestType = request_type?.toLowerCase() || '';
  const validCategories = new Set(['rewards', 'reward', 'stamps', 'game tries', 'loyalty']);
  // special case
  switch (true) {
    case normalizedRequestType === 'reactivate':
      return 'Reactivate Customer';
    case normalizedRequestType === 'deactivate':
      return 'Deactivate Customer';
    case normalizedRequestType === 'void' && normalizedCategory === 'rewards':
      return 'Void Voucher';
    case normalizedRequestType === 'update' && normalizedCategory === 'rewards':
      return 'Modify Voucher';
  }

  if (request_type?.toLowerCase() === 'create' && validCategories.has(normalizedCategory)) {
    let category: string;

    switch (normalizedCategory) {
      case 'rewards':
        category = 'Voucher';
        break;
      case 'loyalty':
        category = 'Loyalty Points';
        break;
      default:
        category = request_category;
    }

    return `Issue ${category}`;
  }
  return request_category && request_type ? `${request_category} ${request_type}` : 'N.A';
};

/**
 * Checks if the user has the "Tenant Admin" role.
 *
 * @param {Array} roles - An array of role objects, each containing a `name` property.
 * @returns {boolean} - Returns `true` if any role has the name "tenant admin" (case insensitive), otherwise `false`.
 */
export const checkIsRoleTenantAdmin = (roles: Array<any> = []): boolean =>
  roles.some(role => ['tenant admin', 'Tenant Admin'].includes(role.name));

/**
 * Determines if the user is allowed to create based on Maker Checker (MC) status and permissions.
 *
 * @param {boolean} isMCenabled - Indicates if the Maker Checker (MC) feature is enabled.
 * @param {string[]} permissions - An array of permissions assigned to the user.
 * @returns {boolean} - Returns `true` if the user is allowed to create, otherwise `false`.
 *
 * @remarks
 * - If MC is enabled and the user has the 'create' permission, the function returns `true`.
 * - If MC is disabled and the user has the 'edit' permission, the function returns `true`.
 * - In all other cases, the function returns `false`.
 *  - THIS FUNCTION SHOULD ONLY BE USED DURING WITHIN THIS CRITERIA IN CAMPAIGNS ONLY.
 */
export const isCreateAllowed = (isMCenabled: boolean, permissions: string[]): boolean => {
  return isMCenabled ? permissions.includes('create') : permissions.includes('edit');
};

/**
 * Determines if a duplicate action is allowed based on Maker-Checker settings and user permissions.
 *
 * @param isMCenabled - A boolean indicating if Maker-Checker is enabled.
 * @param permissions - An array of strings representing the user's permissions.
 * @param isDuplicate - A boolean indicating if the action is a duplicate.
 * @returns A boolean indicating if the duplicate action is allowed.
 *
 * - THIS FUNCTION SHOULD ONLY BE USED DURING WITHIN THIS CRITERIA IN CAMPAIGNS ONLY.
 */
export const isDuplicateActionAllowed = (
  isMCenabled: boolean,
  permissions: string[],
  isDuplicate: boolean
): boolean => {
  if (!isDuplicate) return false;
  return isCreateAllowed(isMCenabled, permissions);
};

/**
 * Generates the difference between two objects, applying filters based on valid keys and default values.
 *
 * This function compares a base object and an updated object to identify differences. It filters out the differences
 * that are not part of a predefined set of valid keys, and then ensures that keys in the valid keys set, which
 * are not part of the diff, are populated with default values specified in the `defaultValuesForDiff` object.
 *
 * @param baseObject - The original object, which is compared against the updated object to find differences.
 * @param updatedObject - The updated object to compare with the base object for differences.
 * @param validKeys - A set of keys that represent valid keys which have been modified by the user.
 * @param defaultValuesForDiff - An object that contains default values for keys that should be added if missing from the diff.
 * @returns An object representing the filtered differences, where valid keys are included and missing keys are populated
 *          with their respective default values from `defaultValuesForDiff`.
 */
export const getFilteredDiff = (
  baseObject: any,
  updatedObject: any,
  validKeys: Set<string>,
  defaultValuesForDiff: Record<string, any>
) => {
  const diffData = getDiff(baseObject, updatedObject);
  const tempDiff = Object.keys(diffData)
    .filter(key => validKeys.has(key))
    .reduce((acc, key) => {
      acc[key] = diffData[key];
      return acc;
    }, {});

  Object.keys(defaultValuesForDiff).forEach(key => {
    if (validKeys.has(key) && !has(tempDiff, key)) {
      tempDiff[key] = defaultValuesForDiff[key];
    }
  });

  return tempDiff;
};

export const getRequestType = (requestType: string | undefined | null): string => {
  switch (requestType) {
    case REQUEST_TYPE.UPDATE:
      return 'Edit';
    case REQUEST_TYPE.PUBLISHED:
      return 'Publish New';
    case REQUEST_TYPE.DEACTIVATE:
      return 'Deactivate';
    case REQUEST_TYPE.REACTIVATE:
      return 'Reactivate';
    default:
      return 'N.A';
  }
};

/**
 * Parses the error message from an error object.
 *
 * @param error - The error object which may contain a response with a data message.
 * @param fallBackErrorSms - An optional fallback error message to use if the error object does not contain a message.
 * @returns The parsed error message, the fallback error message if provided, or a default message 'Something went wrong'.
 */
export const parseErrorMessage = (error: any, fallBackErrorSms?: string): string => {
  return error?.response?.data?.message || fallBackErrorSms || 'Something went wrong';
};
