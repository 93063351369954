import React, { Component, CSSProperties } from 'react';
import styled from 'styled-components';

import { colors, metrics } from '../assets/styles/commonStyle';

const Wrapper = styled.div`
  display: flex;
`;

const Tab = styled.div<{ active: boolean }>`
  border: 1px solid ${props => (props.active ? colors.transparent : colors.buttonDefault)};
  background: ${props => (props.active ? colors.buttonDefault : colors.white)};
  padding: ${metrics.padding.base}px ${metrics.padding.extraLarge}px;
  cursor: pointer;
  color: ${props => (props.active ? colors.white : colors.buttonDefault)};
  :nth-child(n + 2) {
    margin-left: -1px;
  }
`;
interface TabProps {
  name: string;
  active: boolean;
  onClick: () => any;
  dataTestId?: string;
}
interface TabsProps {
  style?: CSSProperties;
  tabs: TabProps[];
}
interface TabsState {
  activeIndex: number;
}
export class Tabs extends Component<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props);
    this.state = {
      activeIndex: this.props.tabs ? this.props.tabs.findIndex(tab => tab.active) : 0
    };
  }

  componentDidUpdate(prevProps: Readonly<TabsProps>): void {
    if (prevProps.tabs !== this.props.tabs) {
      this.setState({
        activeIndex: this.props.tabs ? this.props.tabs.findIndex(tab => tab.active) : 0
      });
    }
  }

  onTabClick = (fn, activeIndex) => () => {
    this.setState({ activeIndex }, () => {
      if (typeof fn === 'function') {
        fn();
      }
    });
  };

  renderTabs = () => {
    return this.props.tabs.map((item, index) => {
      return (
        <Tab
          active={index === this.state.activeIndex}
          key={index}
          onClick={this.onTabClick(item.onClick, index)}
          data-testid={item.dataTestId || `tab_${index}`}
        >
          {item.name.toUpperCase()}
        </Tab>
      );
    });
  };

  render() {
    return <Wrapper style={this.props.style || {}}>{this.renderTabs()}</Wrapper>;
  }
}

export default Tabs;
