import {
  CustomerDeactivationModalKey,
  CustomerDetailsKey,
  CustomerListPageKey,
  CustomerListTableKey,
  CustomerPendingListTableKey,
  CustomerUploadModalKey
} from '../../TModules';

export const translations = {
  [CustomerListTableKey]: {
    Identifier: 'Identifier',
    Name: 'Name',
    Status: 'Status',
    DateJoined: 'Date Joined',
    Actions: 'Actions',
    SearchCustomers: 'Search Customers',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Active: 'Active',
    Inactive: 'Inactive',
    Initial: 'Initial'
  },

  [CustomerUploadModalKey]: {
    HandleUpload: {
      SuccessMessage: 'File uploaded',
      ErrorMessage: 'Error uploading file.',
      Title: 'Upload Customer File',
      OkText: 'Upload',
      CancelText: 'Cancel',
      UploadButton: 'Upload',
      UploadDraggerMessage: 'Click or drag file to this area to upload',
      DownloadSample: 'Download Sample',
      AcceptedFormat: 'Accepted Format: .txt., .xlsx or.csv files only'
    }
  },
  [CustomerPendingListTableKey]: {
    RequestId: 'Request ID',
    Customer: 'Customer',
    RequestType: 'Request Type',
    Status: 'Status',
    Comment: 'Comment',
    LastUpdated: 'Last Updated',
    DateOfExpiry: 'Date of expiry',
    Actions: 'Actions',
    Comments: 'Comments',
    ViewDetails: 'View details',
    Approve: 'Approve',
    ApproveRequests: 'Approve requests',
    CustomerStampView: 'Customer Stamp View',
    LoyaltyProgramDetails: 'Loyalty Program Details',
    CustomerGameView: 'Customer Game View',
    CustomerRewardView: 'Customer Reward View',
    ApproveSelectedButton: 'Approve selected',
    ApproveMultipleMessageModal: {
      SuccessMessage: 'Uploads approved',
      FailureMessage: 'Error approving file.',
      Title: 'Approve multiple requests?',
      OkText: 'Approve selected',
      DescriptionText: `
      Are you sure you want to approve all the selected requests?
      <br />
      Please verify the details before approving as this action cannot be undone.`
    },
    ApproveRequestModal: {
      SuccessMessage: 'Request has been approved',
      FailureMessage: 'Error approving request.',
      Title: 'Approve request?',
      OkText: 'Approve',
      DescriptionText: `Are you sure you want to approve this request?`
    },
    UploadCommentModal: {
      ViewAndAddComments: 'View and add comments',
      Content: 'Content',
      DeleteButton: 'Delete button',
      Time: 'Time',
      ErrorAddingComment: 'Error adding comment',
      CommentAdded: 'Comment added',
      EnterYourCommentHere: 'Enter your comment here',
      AddComment: 'Add comment',
      CommentsAdded: 'Comments added',
      Delete: 'Delete'
    },
    DeleteCommentModal: {
      CommentDeleted: 'Comment deleted',
      ErrorDeletingComment: 'Error deleting comment',
      DeleteComment: 'Delete comment?',
      Delete: 'Delete',
      Description: `
      Are you sure you want to delete your comment?
      <br />
      This action cannot be undone.
      `
    }
  },
  [CustomerListPageKey]: {
    PendingRequests: 'Pending Requests',
    Customers: 'Customers',
    CustomersList: 'Customers List',
    Upload: 'Upload',
    TransactionRequests: 'Transaction requests'
  },
  [CustomerDeactivationModalKey]: {
    ProvideReasonForDeactivation: 'Provide Reason for Deactivation',
    ProvideReasonForActivation: 'Provide Reason for Activation',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    Cancel: 'Cancel',
    DeactivationReason: 'Please select reason'
  },
  [CustomerDetailsKey]: {
    ID: 'ID',
    CustomerDetails: 'Customer Details',
    Identifier: 'Identifier',
    UserInfo: 'User Info',
    FirstName: 'First Name',
    MiddleName: 'Middle Name',
    LastName: 'Last Name',
    Gender: 'Gender',
    Address: 'Address',
    Birthday: 'Birthday',
    JoinedAt: 'Joined At',
    CreatedVia: 'Created Via',
    ReferralCode: 'Referral Code',
    Email: 'Email',
    Phone: 'Phone',
    State: 'State',
    City: 'City',
    Race: 'Race',
    Segment: 'Segment',
    AccountId: 'Account ID',
    Nationality: 'Nationality',
    AlternateId: 'Alternate ID',
    ProductType: 'Product Type',
    Classification: 'Classification',
    LocationState: 'Location State',
    PreferredLanguage: 'Preferred Language',
    FindRewardByNameTypeOrMerchant: 'Find Reward By Name Type Or Merchant',
    IssueRewards: 'Issue Rewards',
    RewardIssueMakerCheckerErrorMessage: 'You cannot approve your own request',
    RewardIssueErrorMessage: 'Error occurred while issuing rewards',
    RewardIssueErrorsOccurredWhileIssuingRewards: `Errors occurred while issuing rewards`,
    RewardIssueSuccessMessage: 'Reward issued successfully',
    RewardIssueMakerCheckerSuccessMessage: 'Reward issue request submitted for approval',
    RewardIssueUnexpectedResponseFormat: 'Unexpected response format',
    ErrorRetrievingCustomer: 'Error Retrieving Customer',
    CustomerRewards: 'Customer Rewards',
    Stamps: 'Stamps',
    Games: 'Games',
    LoyaltyProgram: 'Loyalty program',
    AccountDetails: 'Account Details',
    ProgressCampaign: 'Progress Campaign',
    Campaigns: 'Campaigns',
    CustomerIDNotFound: 'Customer ID not found',
    DeleteUser: 'Delete User',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    ACTIVE: 'ACTIVE',
    DeactivationReason: 'Deactivation Reason',
    AddressState: 'Address State',
    AddressCity: 'Address City',
    Company: 'Company',
    EmailTags: 'Email Tags',
    BusinessType: 'Business Type',
    INACTIVE: 'INACTIVE',

    Name: 'Name',
    Status: 'Status',
    Tenure: 'Tenure',
    Tier: 'Tier',
    Balance: 'Balance',
    ToNextTier: 'To Next Tier',
    MembershipExpiry: 'Membership Expiry',

    Rewards: {
      FindRewardByNameTypeOrMerchant: 'Find reward by name, type or merchant',
      IssueRewardsManually: 'Issue Rewards Manually',
      Issued: 'Issued',
      Pending: 'Pending',
      GiftedAway: 'Gifted Away',
      Redeemed: 'Redeemed',
      RedemptionInProgress: 'Redemption In Progress',
      FullyRedeemed: 'Fully Redeemed',
      Voided: 'Voided',
      Expired: 'Expired',
      Archived: 'Archived',

      Name: 'Name',
      DeletionRequested: 'Deletion Requested',
      ID: 'ID',
      RequestType: 'Request Type',
      Merchant: 'Merchant',
      DateOfIssued: 'Date of Issued',
      ValidFrom: 'Valid From',
      ValidTo: 'Valid To',
      VoucherCode: 'Voucher Code',
      AdminFee: 'Admin Fee',
      Action: 'Action',
      DateOfExpired: 'Date of expired',
      DateOfGifted: 'Date of gifted',
      DateOfRedeemed: 'Date of redeemed',
      RequestId: 'Request ID',
      Status: 'Status',
      RewardId: 'Reward ID',
      IssuedOn: 'Issued on',

      VoidVoucher: 'Void Voucher',
      ChangeExpiryDate: 'Change Expiry Date',
      AmendReward: 'Amend Reward',
      UndoRedeem: 'Undo Redeem',
      ThereIsNoActionForGiftedVoucher: 'There is no action for gifted voucher.',
      RevertBackToIssued: 'Revert back to issued',
      WaiveAdminFee: 'Waive Admin Fee',
      DeleteReward: 'Delete Reward',

      CancelVoidRequest: 'Cancel Void Request',
      CancelUpdateRequest: 'Cancel Update Request',
      CancelIssueRequest: 'Cancel Issue Request',

      ViewComments: 'View comments',
      ApproveIssue: 'Approve issue',

      ViewAndAddComments: 'View and add comments',
      RequestHasBeenApproved: 'Request has been Approved',
      ErrorApprovingRewards: 'Error approving rewards.',
      SomethingWentWrong: 'Something Went Wrong',
      HandleUndoRedeemErrorMessage:
        'Cannot undo redemption - please change the expiry date and try again.',
      HandleUndoRedeemConfirmModalTitle: 'Confirm',
      HandleUndoRedeemConfirmModalMessage:
        'This will revert the voucher state back to Issued - continue?',

      HandleVoidVoucherMakerCheckerSuccessMessage: 'Voiding request submitted for approval',
      HandleVoidVoucherSuccessMessage: 'Voucher is voided',
      HandleVoidVoucherConfirmModalMakerCheckerRightButtonText: 'Request voiding',
      HandleVoidVoucherConfirmModalRightButtonText: 'Confirm',
      HandleVoidVoucherConfirmModalMakerCheckerTitle: 'Submit voiding request?',
      HandleVoidVoucherConfirmModalTitle: 'Confirm',
      HandleVoidVoucherConfirmModalMakerCheckerMessage:
        'Are you sure you want to request the voiding of the reward voucher? Once approved, you will not be able to restore it.',
      HandleVoidVoucherConfirmModalMessage: 'This will void the reward voucher - continue?',

      HandleRevertRedemptionToIssuedConfirmModalTitle: 'Confirm',
      HandleRevertRedemptionToIssuedMessageConfirmModalMessage:
        'This will update the voucher state back to Issued - continue?',

      ShowOCBCDemoConfirmModalTitle: 'Confirm',
      ShowOCBCDemoConfirmModalMessage: 'This will waive the admin fee - continue?'
    },

    IssueRewardsModal: {
      Title: 'Issue Rewards Manually',
      OkText_one: 'Issue {{count}} Reward',
      OkText_other: 'Issue {{count}} Rewards',
      SearchPlaceholder: 'Find reward by name, type or merchant',
      ActiveTabLabel: 'Active',
      PrivateTabLabel: 'Private',
      CancelText: 'Cancel',

      CustomerRewardsConfirmationModal: {
        RewardName: 'Reward Name',
        RewardPrices: 'Reward Prices',
        FreeOrPaid: 'Free/Paid',
        ConfirmSellPrice: 'Confirm Sell Price',
        And: 'and',
        Points: 'points',
        Or: 'OR',
        Paid: 'Paid',
        Free: 'Free',
        Cancel: 'Cancel',
        IssueRewards: 'ISSUE REWARDS'
      }
    },
    IssueRewardsTable: {
      Name: 'Name',
      Merchant: 'Merchant',
      ValidityPeriod: 'Validity Period',
      Balance: 'Balance'
    },
    AuditLog: {
      Title: 'Audit Log',
      LastUpdatedAt: 'Last Updated At',
      IpAddress: 'IP Address',
      User: 'User',
      Action: 'Action',
      Description: 'Description',
      RenderSummaryIsCreated: 'created',
      RenderSummaryIsUpdated: 'updated'
    },
    DeleteCustomerModal: {
      MakesCheckerErrorMessage: 'Implement API integration with maker checker under progress',
      Title: 'Delete User?',
      OkText: 'confirm',
      CancelText: 'Go back',
      DescriptionLineOne: 'Are you sure you want to delete this user?',
      DescriptionLineTwo: 'This action cannot be undone.',
      Name: 'Name:',
      Identifier: 'Identifier:'
    },

    LoyaltyProgramsTable: {
      Name: 'Name',
      Status: 'Status',
      Tenure: 'Tenure',
      Tier: 'Tier',
      Balance: 'Balance',
      ToNextTier: 'To Next Tier',
      MembershipExpiry: 'Membership Expiry',
      Actions: 'Actions'
    },
    LoyaltyProgramDetailsModal: {
      Title: 'Loyalty Program Details',
      CloseButton: 'CLOSE',
      Active: 'Active',
      Pending: 'Pending',
      Actions: 'Actions',
      ActionCancelRequest: 'Cancel Request',
      CancelIssuance: 'Cancel Issuance',
      CancelDeletion: 'Cancel Deletion',
      ActionApproveIssue: 'Approve Issue',
      ActionApproveDeletion: 'Approve Deletion',
      ActionDenyDeletion: 'Deny Deletion',
      ActionComments: 'Comments',
      ActionCommentsModalTitle: 'View and add comments',

      Event: 'Event',
      EventButton: 'Deletion requested',
      Status: 'Status',
      SubmissionDate: 'Submission Date',
      Points: 'Points',
      ExpiresAt: 'Expires at',
      TransactionId: 'Transaction ID',
      AmountTransacted: 'Amount Transacted',
      TransactionDate: 'Transaction date',
      TransactionRef: 'Transaction Ref.',
      Merchant: 'Merchant',
      GlAccountNumber: 'GL Account Number',
      CostCenterId: 'Cost Center Id',
      BranchCode: 'Branch Code',

      ChangeStatus: 'Change Status',
      ChangeTier: 'Change Tier',
      AddOrRemovePoints: 'Add / Remove Points',

      ChangeStatusForm: {
        Active: 'Active',
        Inactive: 'Inactive',
        Save: 'Save'
      },
      ChangeTierForm: {
        Label: 'Override tier expires on:',
        Save: 'Save'
      },
      PointsForm: {
        SelectGlAccount: 'Select GL Account',
        GlAccountType: 'GL Account Type',
        GlAccountName: 'GL Account Name',
        Message: 'Write a message to go with this points adjustment.',
        AdjustPointsButton: 'Adjust Points'
      },
      UpdateLoyaltyProgramCustomerStateSuccessMessage: 'State updated',
      UpdateLoyaltyProgramCustomerStateErrorMessage: 'something went wrong',
      UpdateLoyaltyProgramCustomerTierSuccessMessage: 'Tier updated',
      UpdateLoyaltyProgramCustomerTierErrorMessage: 'something went wrong',
      CreateCustomerLoyaltyTransaction: {
        GLAccountTypeRequiredMessage: 'GL Account Type is required',
        GlAccountNameRequiredMessage: 'GL Account Name is required',
        MakerCheckerSuccessMessage: 'Points issue request submitted for approval',
        SuccessMessage: 'Transaction added',
        ErrorMessage: 'something went wrong'
      },
      DeleteCustomerRequestLoyaltyTransaction: {
        SuccessMessage: 'Request has been cancelled',
        ErrorMessage: 'something went wrong'
      },
      ApproveDenyCustomerRequestLoyaltyTransaction: {
        ApproveSuccessMessage: 'Request has been ',
        RequestHasBeenDeletedMessage: 'Request has been deleted',
        RequestHasBeenApprovedMessage: 'Request has been approved',
        ErrorMessage: 'something went wrong'
      },
      DeleteCustomerLoyaltyTransaction: {
        MakerCheckerSuccessMessage: 'Transaction deletion request submitted for approval',
        SuccessMessage: 'Transaction deleted',
        ErrorMessage: 'something went wrong'
      },
      RecoverCustomerLoyaltyTransaction: {
        SuccessMessage: 'Transaction recovered',
        ErrorMessage: 'something went wrong'
      },
      DeleteCustomerLoyaltyTransactionModal: {
        ActiveTitle: 'Submit deletion request?',
        CancelDeletionTitle: 'Cancel Points Deletion Request?',
        Title: 'Cancel Points Issuance Request?',
        ActiveOkText: 'Request deletion',
        OkText: 'Confirm',
        ActiveCancelText: 'Cancel',
        CancelText: 'Back',
        ActiveMessage:
          'Are you sure you want to request the deletion of these points? Once approved, you will not be able to restore them.',
        MessageLineOne: `The issue of these points is pending approval. Are you sure you want to cancel the request?`,
        MessageLineOneDeletion:
          'The deletion of these points is pending approval. Are you sure you want to cancel it?',
        MessageLineTwo: 'This action cannot be undone.'
      },
      ApproveDenyModal: {
        ApproveIssueTitle: 'Approve loyalty points issue',
        ApproveDeletionTitle: 'Approve Loyalty Points Deletion',
        DenyDeletionTitle: 'Deny loyalty points deletion request',
        DenyDeletionOkText: 'Deny',
        OkText: 'Approve',
        ApproveIssueAndDeletionMessageLineOne: 'Are you sure you want to approve this',
        ApproveIssueAndDeletionMessageLineTwo: 'of loyalty points? This action cannot be undone.',
        Message: `Are you sure you want to deny the deletion of these loyalty points? This action
                    cannot be undone, and the loyalty points will remain active.`,

        Issuance: 'issuance',
        Deletion: 'deletion'
      },

      LineProgressBar: {
        HeaderDateText: 'start of period',
        CustomerTierText: 'The user is currently in',
        Tier: 'Tier',
        CustomerForcedMinimumTierText: 'The user has been forced into minimum',
        CustomerForcedMaximumTierText: `The user has been `,
        FORCED: 'FORCED',
        into: 'into',
        EndOfPeriod: 'end of period',
        ForcedTier: 'Forced tier',
        ForcedMinTier: 'Forced minimum tier',
        UserHasBeen: 'User has been',
        Forced: 'forced',
        To: 'to',
        Status: 'status',
        Until: 'until',
        DeleteForcedTierLinkText: 'X Deactivate Forced Tier',
        ToMinimum: 'to minimum',
        TheUserHasEarned: 'The user has earned',
        Points: 'Point(s)',
        DuringThisCycle: 'during this cycle',
        TheUserRequires: 'The user requires',
        ToLevelUpTo: 'to level up to',
        TheUserHas: 'The user has ',
        Available: 'available.'
      }
    },

    CustomerCampaigns: {
      ID: 'ID',
      HitThePinata: 'Hit The Pinata',
      ScratchCard: 'Scratch Card',
      Plinko: 'Plinko',
      Name: 'Name',
      CampaignType: 'Campaign Type',
      Status: 'Status',
      StartDate: 'Start Date',
      EndDate: 'End Date',
      Actions: 'Actions',
      SearchCampaignsPlaceholder: 'Search Campaigns Placeholder',
      UnenrollCustomer: 'Unenroll customer',
      NotStarted: 'Not Started',
      Active: 'Active',
      Completed: 'Completed',
      NotCompleted: 'Not Completed',
      Enrollable: 'Enrollable',
      EndedAndNotEnrolled: 'Ended And Not Enrolled',
      Enrolled: 'Enrolled',
      NotEnrolled: 'Not Enrolled',
      SpinTheWheel: 'Spin The Wheel',
      ShakeTheTree: 'Shake The Tree',
      EnrollCustomer: 'Enroll customer',
      SelectCampaignsPlaceholder: 'Select Campaigns Placeholder',
      EnrollUserSuccessMessage: 'Campaign enrolled',
      UnenrollUserSuccessMessage: 'Campaign unenrolled',
      SomethingWentWrong: 'Something went wrong',

      SelectedTypeCampaigns: {
        Customers: 'Customers',
        Campaigns: 'Campaigns',
        StampCampaign: 'Stamp Campaign',
        QuizCampaign: 'Quiz Campaign',
        SurveyCampaign: 'Survey Campaign',
        GameCampaign: 'Game Campaign',
        ProgressCampaign: 'Progress Campaign',
        QuestCampaign: 'Quest Campaign',
        InstantRewardCampaign: 'Instant Reward Campaign',
        CampaignProgress: 'This customer is currently on'
      },

      RaffleView: {
        TriggerName: 'Trigger Name',
        RaffleRuleID: 'Raffle Rule ID',
        TransactionDetails: 'Transaction Details',
        TransactionRuleID: 'Transaction Rule ID',
        TriggeredDateOrTime: 'Triggered Date / Time',
        RaffleCode: 'Raffle Code',
        State: 'State'
      },

      InstantRewardView: {
        TriggerName: 'Trigger Name',
        RuleID: 'Rule ID',
        TransactionDetails: 'Transaction Details',
        TriggeredDateOrTime: 'Triggered Date / Time'
      },

      QuestView: {
        TriggerName: 'Trigger Name',
        Task: 'Task',
        QuestRuleID: 'Quest Rule ID',
        TransactionDetails: 'Transaction Details',
        TransactionRuleID: 'Transaction Rule ID',
        TriggeredDateOrTime: 'Triggered Date / Time',
        State: 'State',
        Actions: 'Actions',
        MarkCompleted: 'Mark Completed',
        MarkCompletedSuccessMessage: 'Points updated successfully!',
        MarkCompletedErrorMessage: 'Error Update points'
      },

      CustomerProgressCard: {
        TriggerName: 'Trigger Name',
        ProgressRuleID: 'Progress Rule ID',
        TransactionDetails: 'Transaction Details',
        TransactionID: 'Transaction ID',
        TriggeredDateOrTime: 'Triggered Date / Time',
        Points: 'Points'
      },

      CustomerGameView: {
        TriesID: 'Tries ID',
        Status: 'Status',
        AcquiredVia: 'Acquired Via',
        ReasonCode: 'Reason Code',
        Email: 'Email',
        ID: 'ID',
        DateOfIssue: 'Date of Issue',
        DateOfExpiry: 'Date of Expiry',
        Outcomes: 'Outcomes',
        VoucherID: 'Voucher ID',
        NoOutcome: 'no outcome',
        SorrySomethingWentWrong: 'Sorry, something went wrong!',

        All: 'All',
        Issued: 'Issued',
        Redeem: 'Redeem',
        Expired: 'Expired',
        Pending: 'Pending',

        PendingColumns: {
          RequestID: 'Request ID',
          Status: 'Status',
          Reason: 'Reason',
          NumberOfTries: 'Number of tries',
          DateOfRequest: 'Date of request',
          Actions: 'Actions'
        },

        ApproveIssue: `Approve issue`,
        CancelIssuance: 'Cancel Issuance',
        ViewComments: 'View comments',
        ViewAndAddComments: 'View and add comments',

        ApproveIssueSuccessMessage: 'Request has been approved',
        ApproveIssueErrorMessage: 'Something Went Wrong',

        ApproveDenyModal: {
          Title: 'Approve rewards issue',
          OkText: 'Approve',
          CancelText: 'Cancel',
          Description: `Are you sure you want to approve the issuance of this reward? This action cannot be
            undone.`
        }
      },

      CustomerCardView: {
        Slot: 'Slot #',
        StampId: 'Stamp ID',
        Status: 'Status',
        AcquiredVia: 'Acquired via',
        StampIssuedDate: 'Stamp Issued Date',
        StampRedeemedDate: 'Stamp Redeemed Date',
        Outcomes: 'Outcomes',
        RedeemTheStamp: 'Redeem the stamp',
        EmptySlots: 'Empty Slots',
        SlotEmpty: 'Slot Empty',
        ID: 'ID',

        VoucherID: 'Voucher ID',
        RewardID: 'Reward ID',

        NoRewards: 'No rewards issued',
        NoRewardsIssued: 'No rewards issued',
        Note: 'Note',
        Email: 'Email',
        SorrySomethingWentWrong: 'Sorry, something went wrong'
      },

      CustomerQuizAndSurveyView: {
        QuestionNo: 'Question No.',
        Question: 'Question',
        Response: 'Response',
        SubmissionDateOrTime: 'Submission Date / Time',
        Result: 'Result'
      },

      IssueGameChangeModal: {
        MakerCheckerSuccessMessage: 'Game chance issue request submitted for approval',
        SuccessMessage: 'Game chances issued successfully!',
        ErrorMessage: 'Error issuing game chances',
        IssueGameTriesButton: 'Issue Game Tries',
        IssueGameChanceButton: 'Issue Game Chance',
        Title: 'Issue game chance to',
        OkText: 'Issue Chance',
        HowManyChances: 'How many chances?',
        ReasonForIssuance: 'Reason for issuance',

        ChanceCountValidationMessage: 'Please specify a count of chances.',
        ReasonForIssuanceValidationMessage: 'Please specify a reason for issuance.'
      },

      CustomerStampCards: {
        CardHash: 'Card #',
        StampCardID: 'Stamp Card ID',
        State: 'State',
        ExpiryDate: 'Expiry Date',
        DateOfParticipation: 'Date of Participation',
        StampsCollected: 'Stamps Collected',
        CompletedDate: 'Completed Date',
        RewardsAchieved: 'Rewards Achieved',

        PendingColumns: {
          RequestID: 'Request ID',
          State: 'State',
          Reason: 'Reason',
          NumberOfStamps: 'Number of stamps',
          DateOfRequest: 'Date of request',
          Actions: 'Actions'
        },

        CancelIssuance: 'Cancel Issuance',
        ApproveIssue: 'Approve issue',
        ApproveIssueSuccessMessage: 'Request has been approved',
        ApproveIssueErrorMessage: 'Something Went Wrong',
        ViewComments: 'View comments',

        Active: 'Active',
        Completed: 'Completed',
        Expired: 'Expired',
        Pending: 'Pending',

        SorrySomethingWentWrong: 'Sorry, something went wrong'
      },

      IssueStampsModal: {
        MakerCheckerSuccessMessage: 'Stamp issue request submitted for approval!',
        SuccessMessage: 'Issue stamps successful!',
        ErrorMessage: 'Error Issuing stamps',
        IssueStampsButton: 'Issue Stamps',
        Title: 'Issue stamps to',
        OkText: 'Issue Stamps',
        CancelText: 'Cancel',
        HowManyStamps: 'How many stamps?',
        ReasonForIssuance: 'Reason for issuance',
        CustomerFullNameDefault: 'Current Customer',

        StampsCountValidationMessage: 'Please specify a count of stamps.',
        ReasonToIssuanceValidationMessage: 'Please specify a reason for issuance'
      },

      DeleteRequestModal: {
        SuccessMessage: 'Request has been cancelled',
        ErrorMessage: 'Something Went Wrong',
        Title: 'Cancel {{type}} Issuance Request?',
        OkText: 'Confirm',
        CancelText: 'Cancel',
        DescriptionLineOne:
          'This {{lowerCaseType}} is pending approval. Are you sure you want to cancel it?',
        DescriptionLineTwo:
          'Once the cancellation is approved, this {{lowerCaseType}} will not be available and you will need to create it again.'
      },

      IssueRewardModal: {
        RewardIssuedSuccessfully: 'Reward issued successfully!',
        ErrorIssueReward: 'Error Issue reward',
        IssueRewardTitle: 'Issue Rewards',
        IssueRewardOkText: 'Issue Reward',
        IssueRewardButton: 'Issue Reward',
        ReasonForAdjustment: 'Reason For Adjustment'
      },

      AddRemovePointsModal: {
        PointsUpdatedSuccessfully: 'Points updated successfully!',
        ErrorUpdatingPoints: 'Error Updating points',
        AddOrRemovePointsButton: 'Add/Remove Points',
        AdjustProgressCampaignPoints: 'Adjust Progress Campaign Points',
        OkText: 'Adjust Points',
        HowManyPointsToAddOrRemove: 'How many points to add/remove?',
        ReasonForAdjustment: 'Reason For Adjustment'
      }
    },

    CustomerStamps: {
      All: 'All',
      Active: 'Active',
      Inactive: 'Inactive',

      Name: 'Name',
      Status: 'Status',
      Started: 'Started',
      Ended: 'Ended',
      ID: 'ID',

      SearchPlaceholder: 'Find a campaign by name',

      EnrollCampaignModal: {
        SuccessMessage: 'Campaign enrolled successful!',
        ErrorMessage: 'Campaign enrolled failed.',
        CampaignIDValidationMessage: 'Please specify a campaign',
        FullNameDefault: 'Current Customer',

        ManualEnrollButton: 'Manual Enroll',
        Title: 'Enroll',
        OkText: 'Enroll Customer',
        CancelText: 'Cancel'
      },

      CampaignSelectField: {
        Label: 'Select Campaign',
        Edit: 'Edit',
        Add: 'Add',
        Campaign: 'Campaign'
      },

      CampaignSelectModal: {
        Title: 'Select a Campaign',
        CancelText: 'Cancel',
        OkText: 'Add Campaign',
        SearchPlaceholder: 'Find a campaign by name, type or merchant',

        Name: 'Name',
        ID: 'ID',
        Status: 'Status',
        Tags: 'Tags',
        StartDate: 'Start Date',
        EndDate: 'End Date'
      }
    },

    CustomerGames: {
      Name: 'Name',
      ID: 'ID',
      Status: 'Status',
      GameType: 'Game Type',
      TriesRedeemed: 'Tries Redeemed',
      Started: 'Started',
      Ended: 'Ended',
      SearchPlaceholder: 'Find a campaign by name',
      SorrySomethingWentWrong: 'Sorry, something went wrong',

      Tabs: {
        All: 'All',
        Active: 'Active',
        Approved: 'Approved',
        Inactive: 'Inactive',
        Ended: 'Ended'
      }
    }
  }
};
