import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import moment from 'moment-timezone';

import { useBDOTransferRequestAuditLog } from 'api/bdo/audit-log';
import { MID_PAGE_SIZE } from 'appConstants';
import Error from 'containers/Analytics/components/Error';
import usePermissions from 'containers/Permissions/usePermissions';
import { useListState } from 'hooks';

interface BDOTransferRequestAuditLogProps {
  id: number | string | null;
  state: string;
}

const BDOTransferRequestAuditLog = ({ id, state }: BDOTransferRequestAuditLogProps) => {
  const { handleTableChange, pagination, params } = useListState({ size: MID_PAGE_SIZE });

  const { canView } = usePermissions('audit_logs');

  const { data, error, isLoading, meta, mutate } = useBDOTransferRequestAuditLog(
    canView ? id : null,
    params
  );

  useEffect(() => {
    mutate();
  }, [state]);

  if (!canView) return null;

  if (error) {
    return (
      <Card title="DETAILS" style={{ marginTop: '1rem' }} loading={isLoading}>
        <Error retry={mutate} />
      </Card>
    );
  }

  function humanize(str) {
    if (!str) {
      return;
    }

    const words = str.toString().split('_');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(' ');
  }

  const customRenderUpdateBy = (index, data) => {
    if (
      index === 0 &&
      (data?.request_type === 'BR-CashCred' || data?.request_type === 'BR-SMAC') &&
      data?.state === 'in_progress'
    ) {
      return 'system';
    }

    return data?.changes?.updated_by?.[0] || '-';
  };
  return (
    <Card title="DETAILS" style={{ marginTop: '1rem' }} loading={isLoading} data-testid="audit-log">
      <Table
        onChange={handleTableChange}
        loading={isLoading}
        pagination={{
          ...pagination,
          total: meta?.total_count || 0
        }}
        dataSource={data}
        columns={[
          {
            title: 'Date',
            key: 'date',
            render: (_, record) => {
              return record?.created_at ? moment(record?.created_at).format('MM-DD-YYYY') : '-';
            }
          },
          {
            title: 'Time',
            key: 'time',
            render: (_, record) =>
              record?.created_at ? moment(record?.created_at).format('HH:mm:ss') : '-'
          },
          {
            title: 'Updated by',
            key: 'updated_by',
            render: (text, record, index) => customRenderUpdateBy(index, record)
          },
          {
            title: 'Status',
            key: 'state',
            render: (_, record) => humanize(record?.changes?.state?.[0]) || '-'
          }
        ]}
      />
    </Card>
  );
};

export default BDOTransferRequestAuditLog;
