import { PERMISSIONS } from 'containers/Permissions/constants';
import usePermissions from 'containers/Permissions/usePermissions';

const useCustomerPermissions = () => {
  const loyaltyPermissions = usePermissions(PERMISSIONS.CUSTOMER_MANAGEMENT_LOYALTY);
  const gamePermissions = usePermissions(PERMISSIONS.CUSTOMER_MANAGEMENT_GAME);
  const stampsPermissions = usePermissions(PERMISSIONS.CUSTOMER_MANAGEMENT_STAMP);
  const rewardsPermissions = usePermissions(PERMISSIONS.CUSTOMER_MANAGEMENT_REWARD);
  const customerPermissions = usePermissions(PERMISSIONS.CUSTOMER_MANAGEMENT);
  return {
    loyalty: loyaltyPermissions,
    game_tries: gamePermissions,
    stamps: stampsPermissions,
    rewards: rewardsPermissions,
    user_accounts: customerPermissions
  };
};

export default useCustomerPermissions;
