import produce from 'immer';

import { IScheduleTask } from 'api/schedule';
import {
  SET_INITIAL_DATA,
  CLEAR_INITIAL_DATA
} from 'containers/ScheduleTasks/interfaces/schedule-interfaces';

// Action creators
export const setInitialData = (data: IScheduleTask) => ({
  type: SET_INITIAL_DATA,
  payload: data
});

export const clearInitialData = () => ({
  type: CLEAR_INITIAL_DATA
});

// Initial state
interface ScheduleTaskState {
  initialData: IScheduleTask;
}

const initialState: ScheduleTaskState = {
  initialData: null
};

// Reducer
const scheduleTaskReducer = (
  state: ScheduleTaskState = initialState,
  action
): ScheduleTaskState => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      return produce(state, draft => {
        draft.initialData = action.payload;
      });
    case CLEAR_INITIAL_DATA:
      return produce(state, draft => {
        draft.initialData = null;
      });
    default:
      return state;
  }
};

export default scheduleTaskReducer;
