import merge from 'lodash/merge';

import { getCampaignDetails, getRootCampaign } from './actions';
import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_LOYALTY_LIST,
  GET_LOYALTY_LIST_FAIL,
  GET_LOYALTY_LIST_SUCCESS,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_FAIL,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_FAIL,
  SEARCH_CAMPAIGN,
  SEARCH_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN_FAIL,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  ORDER_CAMPAIGN,
  ORDER_CAMPAIGN_FAIL,
  ORDER_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN_SUCCESS,
  ACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN,
  GET_CONDITIONS,
  GET_CONDITIONS_SUCCESS,
  GET_CONDITIONS_FAIL
} from './constants';

const initialState = {
  loading: false,
  catalogues: [],
  catalogue: {},
  rewards: {
    data: [],
    meta: {}
  },
  campaign: {},
  rootCampaign: {},
  tags: [],
  brands: [],
  campaigns: [],
  audiences: [],
  audience: {},
  races: [],
  cities: [],
  custom: [],
  states: [],
  loyalty_list: [],
  conditionFields: [
    {
      key: 'transaction_amount',
      type: 'number',
      label: 'Transaction amount',
      operators: [
        {
          label: 'Less than',
          key: 'less_than',
          type: 'input'
        },
        {
          label: 'Greater than',
          key: 'greater_than',
          type: 'input'
        },
        {
          label: 'is equal to',
          key: 'equals',
          type: 'input'
        }
      ]
    },

    {
      key: 'transaction_date',
      label: 'Transaction date',
      type: 'date',
      operators: [
        {
          label: 'day of week',
          key: 'day_of_week',
          type: 'multi-select',
          values: [
            { key: 'monday', label: 'monday' },
            { key: 'tuesday', label: 'tuesday' },
            { key: 'wed', label: 'Wed' }
          ]
        },
        {
          label: 'day of month',
          type: 'multi-select',
          values: [
            { key: 1, label: '1' },
            { key: 2, label: '2' },
            { key: 3, label: '3' }
          ]
        },
        {
          label: 'Equals',
          key: 'equals',
          type: 'date_select'
        }
      ]
    }
  ]
};

function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tags: merge([], state.tags, action.payload.data)
      };
    }

    case CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case EDIT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case DEACTIVATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case ACTIVATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_LOYALTY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loyalty_list: action.payload.data
      };
    }

    case GET_REWARDS: {
      return {
        ...state
        // loading: true,
      };
    }
    case GET_REWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }

    case GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        // if override validity data, here
        campaigns: action.payload.data
      };
    }

    case getCampaignDetails.fulfilled.type: {
      return {
        ...state,
        loading: false,
        campaign: action.payload.data
      };
    }

    case getRootCampaign.fulfilled.type: {
      return {
        ...state,
        loading: false,
        rootCampaign: action.payload.data
      };
    }

    case ORDER_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_AUDIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        audiences: action.payload.data
      };
    }

    case GET_AUDIENCE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        audience: action.payload.data
      };
    }

    case SEARCH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaigns: action.payload.data
      };
    }
    case GET_TAGS:
    case CREATE_CAMPAIGN:
    case EDIT_CAMPAIGN:
    case DEACTIVATE_CAMPAIGN:
    case ACTIVATE_CAMPAIGN:
    case GET_LOYALTY_LIST:
    case GET_CAMPAIGNS:
    case ORDER_CAMPAIGN:
    case GET_AUDIENCES:
    case GET_AUDIENCE_INFO:
    case SEARCH_CAMPAIGN:
    case GET_CONDITIONS:
    case SEARCH_REWARD: {
      return {
        ...state,
        error: {},
        loading: true
      };
    }
    case getCampaignDetails.pending.type: {
      return {
        ...state,
        campaign: {},
        error: {},
        loading: true
      };
    }

    case getRootCampaign.pending.type: {
      return {
        ...state,
        rootCampaign: {},
        error: {},
        loading: true
      };
    }

    case SEARCH_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }

    case getCampaignDetails.rejected.type:
    case getRootCampaign.rejected.type:
    case CREATE_CAMPAIGN_FAIL:
    case EDIT_CAMPAIGN_FAIL:
    case DEACTIVATE_CAMPAIGN_FAIL:
    case ACTIVATE_CAMPAIGN_FAIL:
    case GET_LOYALTY_LIST_FAIL:
    case GET_REWARDS_FAIL:
    case GET_CAMPAIGNS_FAIL:
    case ORDER_CAMPAIGN_FAIL:
    case SEARCH_CAMPAIGN_FAIL:
    case GET_AUDIENCES_FAIL:
    case GET_AUDIENCE_INFO_FAILED:
    case GET_TAGS_FAIL:
    case GET_CONDITIONS_FAIL:
    case SEARCH_REWARD_FAIL: {
      return {
        ...state,
        error: action.error.data,
        loading: false
      };
    }

    case GET_CONDITIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        conditionFields: action.payload.data
      };
    }

    default:
      return state;
  }
}

export default campaignReducer;
