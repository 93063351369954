import React, { useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Card, Result, Tooltip, Descriptions } from 'antd';
import { Col, Row } from 'antd';
import _camelCase from 'lodash/camelCase';
import _upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Tabs } from 'components';
import { CircleContainer, TextWrap } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { useAxios } from 'hooks';
import useMultiLanguageTranslation from 'hooks/useMultiLanguageTranslation';
import i18n from 'utils/i18n';

import { TCustomerDetails } from '../i18n/TModules';
import CustomerLoyaltyTable from './CustomerLoyaltyTable';
import CustomerRewardsTable from './CustomerRewardsTable';

const CustomerDetail = ({ customerId, token }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, response, error] = useAxios(`/v4/dash/customers/${customerId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    //@ts-ignore
    external: true
  });

  const { t } = useMultiLanguageTranslation();

  if (error) {
    return (
      <Result
        status="error"
        title="404"
        subTitle={t(`${TCustomerDetails}.ErrorRetrievingCustomer`)}
      />
    );
  }

  if (loading) {
    return <div>{t(`${TCustomerDetails}.Loading`)}</div>;
  }

  let customer;

  if (response?.data) {
    customer = response.data;
  } else {
    return <div>{t(`${TCustomerDetails}.Loading`)}</div>;
  }

  return (
    <>
      <Card title={t(`${TCustomerDetails}.CustomerDetails`)} style={{ marginBottom: '1rem' }}>
        <Row align="middle" justify="center" gutter={24}>
          <Col span={8}>
            <Wrapper>
              <CircleContainer>
                <img style={{ height: '150px', width: '150px' }} alt="" />
              </CircleContainer>
              <Label size="16px" dark bold uppercase nomargin>
                {customer.customer_id}
              </Label>
              {customer.alternate_id && (
                <TextWrap
                  style={{
                    margin: 'auto',
                    width: '100%',
                    textOverflow: 'unset',
                    whiteSpace: 'normal',
                    textAlign: 'center'
                  }}
                >
                  <Label color="#9b9b9b">
                    <span>{t(`${TCustomerDetails}.ID`)}:&#160;&#160;</span>
                  </Label>
                  {customer.alternate_id && customer.alternate_id.length > 10 ? (
                    <Tooltip title={customer.alternate_id}>
                      <LinkOutlined />
                      {customer.alternate_id.slice(0, 10) + '...'}
                    </Tooltip>
                  ) : (
                    customer.alternate_id
                  )}
                </TextWrap>
              )}
            </Wrapper>
          </Col>
          <Col span={16}>
            {customer.personal_properties && (
              <Descriptions
                title={t(`${TCustomerDetails}.UserInfo`)}
                layout="horizontal"
                colon={false}
              >
                {Object.keys(customer.personal_properties).map(property => {
                  if (property === 'forced_loyalty_tier_id') {
                    return (
                      <Descriptions.Item key={property} label={'Tier'}>
                        {customer.personal_properties[property]}
                      </Descriptions.Item>
                    );
                  } else {
                    return (
                      property !== 'service_profile' && (
                        <Descriptions.Item
                          key={property}
                          label={
                            i18n.exists(`${TCustomerDetails}.${_upperFirst(_camelCase(property))}}`)
                              ? t(`${TCustomerDetails}.${_upperFirst(_camelCase(property))}}`)
                              : _upperFirst(_camelCase(property))
                          }
                        >
                          {customer.personal_properties[property]}
                        </Descriptions.Item>
                      )
                    );
                  }
                })}
              </Descriptions>
            )}
          </Col>
        </Row>
      </Card>
      <Card title={t(`${TCustomerDetails}.RewardAdded`)}>
        <TabsWrapper>
          <Tabs
            tabs={[
              { name: 'CUSTOMER REWARDS', active: activeTab === 0, onClick: () => setActiveTab(0) },
              { name: 'LOYALTY PROGRAM', active: activeTab === 1, onClick: () => setActiveTab(1) }
            ]}
          />
        </TabsWrapper>
        {activeTab === 0 && <CustomerRewardsTable token={token} customerId={customer.id} />}
        {activeTab === 1 && <CustomerLoyaltyTable token={token} customerId={customer.id} />}
      </Card>
    </>
  );
};

const TabsWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

CustomerDetail.propTypes = {
  customerId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired
};

export default CustomerDetail;
