import { message } from 'antd';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { makeSelectAuthUser } from 'containers/App/selectors';

import { commonSaga } from '../../middleware/api';
import {
  cataloguesGetFail,
  cataloguesGetSuccess,
  catalogueGetFail,
  catalogueGetSuccess,
  rewardsGetSuccess,
  rewardsGetFail,
  tagsGetSuccess,
  tagsGetFail,
  catalogueCreateSuccess,
  catalogueCreateFail,
  catalogueActivateSuccess,
  catalogueActivateFail,
  catalogueDeactivateSuccess,
  catalogueDeactivateFail, // catalogueOrderSuccess,
  // catalogueOrderFail,
  catalogueUpdateSuccess,
  catalogueUpdateFail,
  catalogueSearchSuccess,
  catalogueSearchFail,
  searchRewardSuccess,
  searchRewardFail
} from './actions';
import {
  createCatalogue,
  updateCatalogue,
  activateCatalogue,
  deactivateCatalogue,
  getCatalogues,
  getCatalogue,
  getRewards,
  getTags,
  searchReward,
  searchCatalogues
} from './api';
import {
  GET_CATALOGUES,
  GET_CATALOGUE,
  CREATE_CATALOGUE,
  UPDATE_CATALOGUE,
  ORDER_CATALOGUE,
  ACTIVATE_CATALOGUE,
  DEACTIVATE_CATALOGUE,
  GET_REWARDS,
  GET_TAGS,
  SEARCH_CATALOGUE,
  SEARCH_REWARD
} from './constants';
import { removeTempProperty } from './utils';

function* catalogueCreate(action) {
  // TODO: separte axios as API service
  const authUser = yield select(makeSelectAuthUser());
  const data = removeTempProperty(action.payload);
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  let cancelMessage;
  try {
    cancelMessage = message.loading('Submitting data...', 0);
    const response = yield call(createCatalogue, data, headers);
    setTimeout(cancelMessage, 200);
    yield put(catalogueCreateSuccess(response.data));
    //yield call(history.push, "/p/catalogues/list");
    action.history.push(`/p/catalogues/show/${response.data.data.id}`);
  } catch (e) {
    setTimeout(cancelMessage, 200);
    yield put(catalogueCreateFail(e.data));
    if (e.data.message) {
      message.error(e.data.message);
    } else {
      message.error('Error creating a catalogue');
    }
  }
}

function* catalogueUpdate(action) {
  // TODO: separte axios as API service
  const authUser = yield select(makeSelectAuthUser());
  const data = removeTempProperty(action.payload);

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  let cancelMessage;
  try {
    cancelMessage = message.loading('Submitting data...', 0);
    const response = yield call(updateCatalogue, data.id, data, headers);
    setTimeout(cancelMessage, 200);
    yield put(catalogueUpdateSuccess(response.data));
    action.history.push(`/p/catalogues/show/${data.id}`);
  } catch (e) {
    setTimeout(cancelMessage, 200);
    yield put(catalogueUpdateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* catalogueActivate(action) {
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(activateCatalogue, data.id, headers);
    yield put(catalogueActivateSuccess(response.data));
    if (data.page && data.page === 'list') {
      yield call(cataloguesGet);
    }
    message.success('Successfully Activated!');
  } catch (e) {
    yield put(catalogueActivateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* catalogueDeactivate(action) {
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(deactivateCatalogue, data.id, headers);
    yield put(catalogueDeactivateSuccess(response.data));
    if (data.page && data.page === 'list') {
      yield call(cataloguesGet);
    }
    message.success('Successfully deactivated!');
  } catch (e) {
    yield put(catalogueDeactivateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* cataloguesGet() {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCatalogues, headers);
    yield put(cataloguesGetSuccess(response.data));
  } catch (e) {
    yield put(cataloguesGetFail(e.data));
  }
}

function* catalogueGet(action) {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCatalogue, action.payload, headers);
    yield put(catalogueGetSuccess(response.data));
    yield put(tagsGetSuccess({ data: response.data.data.tags }));
  } catch (e) {
    if (e.data.message) {
      message.error(e.data.message);
    } else {
      message.error('Failed to load Catalogue');
    }
    yield put(catalogueGetFail(e.data));
  }
}

function* rewardsGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getRewards, params, headers);
    yield put(rewardsGetSuccess(response.data));
  } catch (e) {
    yield put(rewardsGetFail(e.data));
  }
}

function* tagsGet() {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getTags, headers);
    yield put(tagsGetSuccess(response.data));
  } catch (e) {
    yield put(tagsGetFail(e.data));
  }
}

function* catalogueSearch(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchCatalogues, params, headers);
    yield put(catalogueSearchSuccess(response.data));
  } catch (e) {
    yield put(catalogueSearchFail(e.data));
  }
}

function* rewardSearch(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchReward, params, headers);
    yield put(searchRewardSuccess(response.data));
  } catch (e) {
    yield put(searchRewardFail(e.data));
  }
}

function* catalogueSaga() {
  yield takeLatest(CREATE_CATALOGUE, catalogueCreate);
  yield takeLatest(UPDATE_CATALOGUE, catalogueUpdate);
  yield takeLatest(ORDER_CATALOGUE, commonSaga);
  yield takeLatest(ACTIVATE_CATALOGUE, catalogueActivate);
  yield takeLatest(DEACTIVATE_CATALOGUE, catalogueDeactivate);
  yield takeLatest(GET_CATALOGUES, cataloguesGet);
  yield takeLatest(GET_CATALOGUE, catalogueGet);
  yield takeLatest(GET_REWARDS, rewardsGet);
  yield takeLatest(GET_TAGS, tagsGet);
  yield takeLatest(SEARCH_CATALOGUE, catalogueSearch);
  yield takeLatest(SEARCH_REWARD, rewardSearch);
}

export default catalogueSaga;
