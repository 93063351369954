import produce from 'immer';
import _toSnakeCase from 'lodash/snakeCase';

import { FeatureFlagKey } from 'appConstants';
import { checkIsRoleTenantAdmin } from 'containers/utils';
import { defaultFeatures } from 'hooks/useFeatureFlags';

import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAIL,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_USER_FAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  VALIDATE_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_FAIL,
  VALIDATE_RESET_PASSWORD_SUCCESS,
  VALIDATE_INIT_CHANGE_PASSWORD,
  VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS,
  VALIDATE_INIT_CHANGE_PASSWORD_FAIL,
  INIT_CHANGE_PASSWORD,
  INIT_CHANGE_PASSWORD_SUCCESS,
  INIT_CHANGE_PASSWORD_PASSWORD_FAIL,
  GET_NECESSITIES,
  GET_NECESSITIES_SUCCESS,
  GET_NECESSITIES_FAIL,
  CHANGE_APP_LOCALE,
  USER_EXTEND_SESSION_SUCCESS,
  USER_EXTEND_SESSION,
  USER_EXTEND_SESSION_FAIL,
  OTP_REQUIRED,
  CANCEL_OTP,
  SWITCH_TENANT,
  SWITCH_TENANT_COMPLETE,
  TOKEN_SIGNIN,
  TOKEN_SIGNIN_SUCCESS,
  TOKEN_SIGNIN_FAIL,
  CODE_SIGNIN,
  GET_CUSTOMER_PENDING_REQUEST,
  GET_CUSTOMER_PENDING_REQUEST_SUCCESS,
  GET_CUSTOMER_PENDING_REQUEST_FAIL,
  GET_CAMPAIGN_PENDING_REQUEST,
  GET_CAMPAIGN_PENDING_REQUEST_SUCCESS,
  GET_CAMPAIGN_PENDING_REQUEST_FAIL,
  GET_REWARD_PENDING_REQUEST,
  GET_REWARD_PENDING_REQUEST_SUCCESS,
  GET_REWARD_PENDING_REQUEST_FAIL,
  GET_RULE_PENDING_REQUEST,
  GET_RULE_PENDING_REQUEST_SUCCESS,
  GET_RULE_PENDING_REQUEST_FAIL
} from './constants';

export interface TenantConfig {
  id: number | null;
  name: string;
  displayName: string;
  features: Partial<Record<FeatureFlagKey, boolean>>;
  blackcomb_url?: string;
  localization: {
    availableLocales: {
      locales: string[];
      platformSupportedLocales: string[];
    };
    country: string;
    currency: string;
    timezone: string;
    currency_conversion_rate?: number;
    provision_rate?: number;
  };
}

export interface GlobalState {
  loading: boolean;
  tenantConfig: TenantConfig;
  bulkActions;
  localeSwitcherLocales;
  availableLocales;
  selectedLocale;
  reportDownloads?;
  scheduledReports?;
  audienceLists;
  brands;
  categories;
  labels;
  user_tenants;
  currency: string | null;
  currentUser;
  authUser;
  password;
  error;
  tokenError: boolean;
  isEmailSent;
  validReset;
  initValidInviteChange;
  initPwdChange;
  customFilters;
  otpRequired: boolean;
  switchingTenant: boolean;
  customerPendingRequest;
  campaignPendingRequest;
  rewardPendingRequest;
  rulePendingRequest;
  isRoleTenantAdmin: boolean;
}

const initialState: GlobalState = {
  loading: false,
  currentUser: localStorage.getItem('currentUser') === 'true',
  authUser: JSON.parse(localStorage.getItem('authUser')) || {},
  // authUser: null,
  password: null,
  error: null,
  tokenError: null,
  isEmailSent: null,
  validReset: null,
  initValidInviteChange: null,
  initPwdChange: null,
  tenantConfig: {
    id: null,
    name: '',
    displayName: '',
    features: defaultFeatures,
    localization: {
      availableLocales: {
        locales: ['en'],
        platformSupportedLocales: ['en']
      },
      country: 'Singapore',
      currency: 'SGD',
      timezone: 'Asia/Singapore'
    }
  },
  bulkActions: [],
  audienceLists: { data: [] },
  brands: { data: [] },
  user_tenants: { data: [] },
  customFilters: [],
  categories: { data: [], meta: {} },
  labels: { data: [], meta: {} },
  localeSwitcherLocales: { locales: ['en'] },
  availableLocales: { locales: ['en'] },
  selectedLocale: 'en',
  currency: null,
  otpRequired: false,
  switchingTenant: false,
  customerPendingRequest: { data: [], meta: {}, error: null },
  campaignPendingRequest: { data: [], meta: {}, error: null },
  rewardPendingRequest: { data: [], meta: {}, error: null },
  rulePendingRequest: { data: [], meta: {}, error: null },
  isRoleTenantAdmin:
    JSON.parse(localStorage.getItem('authUser'))?.roles?.some(role =>
      ['tenant admin', 'Tenant Admin'].includes(role.name)
    ) || false
};

function globalReducer(state: GlobalState = initialState, action): GlobalState {
  switch (action.type) {
    case SIGNIN_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentUser: true,
        authUser: action.payload,
        otpRequired: false,
        isRoleTenantAdmin: checkIsRoleTenantAdmin(action.payload?.roles)
      };
    }
    case SIGNIN_USER_FAIL: {
      return {
        ...state,
        loading: false,
        currentUser: false,
        authUser: {},
        isRoleTenantAdmin: false
      };
    }
    case CODE_SIGNIN: {
      return {
        ...state,
        loading: true,
        authUser: action.payload,
        isRoleTenantAdmin: checkIsRoleTenantAdmin(action.payload?.roles)
      };
    }
    case OTP_REQUIRED: {
      return {
        ...state,
        loading: false,
        otpRequired: true
      };
    }
    case CANCEL_OTP: {
      return {
        ...state,
        otpRequired: false
      };
    }
    case TOKEN_SIGNIN: {
      return {
        ...state,
        loading: true,
        authUser: action.payload,
        isRoleTenantAdmin: checkIsRoleTenantAdmin(action.payload?.roles)
      };
    }
    case TOKEN_SIGNIN_SUCCESS: {
      return {
        ...state,
        currentUser: true,
        authUser: action.payload,
        loading: false,
        isRoleTenantAdmin: checkIsRoleTenantAdmin(action.payload?.roles)
      };
    }
    case TOKEN_SIGNIN_FAIL: {
      return {
        ...state,
        currentUser: false,
        authUser: null,
        loading: false
      };
    }
    case SWITCH_TENANT: {
      return {
        ...state,
        switchingTenant: true
      };
    }
    case SWITCH_TENANT_COMPLETE: {
      return {
        ...state,
        switchingTenant: false
      };
    }
    case USER_EXTEND_SESSION: {
      return {
        ...state,
        loading: true
      };
    }
    case USER_EXTEND_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        authUser: action.payload,
        isRoleTenantAdmin: checkIsRoleTenantAdmin(action.payload?.roles)
      };
    }
    case USER_EXTEND_SESSION_FAIL: {
      return {
        ...state,
        loading: false,
        currentUser: false,
        authUser: {},
        isRoleTenantAdmin: false
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentUser: false,
        authUser: {},
        isRoleTenantAdmin: false
      };
    }

    case FORGET_PASSWORD_FAIL:
    case SIGNOUT_USER_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case VALIDATE_RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        tokenError: true
      };
    }
    case RESET_PASSWORD:
    case VALIDATE_RESET_PASSWORD:
    case FORGET_PASSWORD: {
      return {
        ...state,
        loading: true
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        password: action.payload
      };
    }
    case FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isEmailSent: action.payload
      };
    }
    case VALIDATE_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        validReset: action.payload,
        tokenError: false
      };
    }

    case VALIDATE_INIT_CHANGE_PASSWORD: {
      return {
        ...state,
        loading: true
      };
    }
    case VALIDATE_INIT_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        initValidInviteChange: action.payload
      };
    }
    case VALIDATE_INIT_CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        tokenError: true
      };
    }

    case INIT_CHANGE_PASSWORD: {
      return {
        ...state,
        loading: true
      };
    }
    case INIT_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        initPwdChange: action.payload
      };
    }
    case INIT_CHANGE_PASSWORD_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case GET_NECESSITIES:
      return produce(state, draft => {
        draft.loading = true;
      });

    case GET_NECESSITIES_SUCCESS: {
      const newState = produce(state, draft => {
        draft.loading = false;
        draft.tenantConfig = action.tenantConfig;
        draft.tenantConfig.name = action.tenantConfig.schema_name;
        draft.tenantConfig.displayName = action.tenantConfig.name;
        draft.tenantConfig.features = { ...defaultFeatures, ...action.tenantConfig.features };
        draft.bulkActions = action.bulkActions;
        draft.localeSwitcherLocales = action.availableLocales;
        draft.availableLocales = {
          locales: action?.availableLocales?.locales?.map(locale => _toSnakeCase(locale))
        };
        draft.selectedLocale = action.selectedLocale;
        draft.reportDownloads = action.reportDownloads;
        draft.scheduledReports = action.scheduledReports;
        draft.audienceLists = action.audienceLists;
        draft.brands = action.brands;
        draft.categories = action.categories;
        draft.labels = action.labels;
        draft.user_tenants = action.user_tenants;
        draft.currency = action.currency;
      });

      return { ...newState };
    }

    case GET_NECESSITIES_FAIL: {
      return produce(state, draft => {
        draft.loading = false;
        draft.error = action.error;
        // draft.currentUser = null;
      });
    }

    case CHANGE_APP_LOCALE:
      return produce(state, draft => {
        draft.selectedLocale = action.locale;
      });

    case GET_CUSTOMER_PENDING_REQUEST:
      return {
        ...state,
        customerPendingRequest: {
          loading: true
        }
      };
    case GET_CUSTOMER_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        customerPendingRequest: {
          loading: false,
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    case GET_CUSTOMER_PENDING_REQUEST_FAIL:
      return {
        ...state,
        customerPendingRequest: {
          loading: false,
          data: [],
          meta: {},
          error: action.error
        }
      };

    case GET_CAMPAIGN_PENDING_REQUEST:
      return {
        ...state,
        campaignPendingRequest: {
          loading: true
        }
      };
    case GET_CAMPAIGN_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        campaignPendingRequest: {
          loading: false,
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    case GET_CAMPAIGN_PENDING_REQUEST_FAIL:
      return {
        ...state,
        campaignPendingRequest: {
          loading: false,
          data: [],
          meta: {},
          error: action.error
        }
      };

    case GET_REWARD_PENDING_REQUEST:
      return {
        ...state,
        rewardPendingRequest: {
          loading: true
        }
      };
    case GET_REWARD_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        rewardPendingRequest: {
          loading: false,
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    case GET_REWARD_PENDING_REQUEST_FAIL:
      return {
        ...state,
        rewardPendingRequest: {
          loading: false,
          data: [],
          meta: {},
          error: action.error
        }
      };

    case GET_RULE_PENDING_REQUEST:
      return {
        ...state,
        rulePendingRequest: {
          loading: true
        }
      };
    case GET_RULE_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        rulePendingRequest: {
          loading: false,
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    case GET_RULE_PENDING_REQUEST_FAIL:
      return {
        ...state,
        rulePendingRequest: {
          loading: false,
          data: [],
          meta: {},
          error: action.error
        }
      };
    default:
      return state;
  }
}

export default globalReducer;
