export enum ViewMode {
  CREATE = 'create',
  READ = 'read',
  EDIT = 'edit'
}

export enum Outcome {
  CAMPAIGN_ENROLLMENT = 'campaign_enrollment',
  GAME_TRY = 'campaign_game_try',
  PUSH_NOTIFICATION = 'campaign_promotion_notification'
}
export enum CampaignType {
  STAMP = 'stamp',
  GAME = 'game',
  QUIZ = 'quiz',
  SURVEY = 'survey',
  INSTANT_REWARD = 'instant_outcome',
  PROGRESS = 'progress',
  QUEST = 'quest'
}

export const outcomeTypeMapping = {
  [Outcome.CAMPAIGN_ENROLLMENT]: 'Campaign Enrollment',
  [Outcome.GAME_TRY]: 'Game Try',
  [Outcome.PUSH_NOTIFICATION]: 'Push Notification'
};

export const campaignTypeMapping = {
  [CampaignType.STAMP]: 'Stamp',
  [CampaignType.GAME]: 'Game',
  [CampaignType.QUIZ]: 'Quiz',
  [CampaignType.SURVEY]: 'Survey'
};

export interface IOutcomeModal {
  // Outcome or task rules
  id: number;
  campaign_type: string;
  outcome_type: string;
  campaign_id: number;
  bonus_tries: number;
  bonus_stamps: number;
  media_tag: string | number[];
  custom_fields: any;
  message_channel: any; // array of strings
  outcome_value?: number | string;
}

export enum SCHEDULEDTASK_STATES {
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  PROCESSING = 'processing',
  ENDED = 'ended',
  INACTIVE = 'inactive',
  FAILED = 'failed'
}

export const INVALID_CAMPAIGN_TYPES_FOR_GAME_TRY: CampaignType[] = [
  CampaignType.PROGRESS,
  CampaignType.QUEST
];

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const CLEAR_INITIAL_DATA = 'CLEAR_INITIAL_DATA';

export interface IScheduleTaskData {
  name: string;
  date: string;
  time: string;
  viewMode: ViewMode;
  id?: number;
  outcome: IOutcomeModal[];
  exclude_audience_ids: string[];
  include_audience_ids: string[];
  audience_version: string;
  v4_audience_ids: string[];
  filters: IFilters;
}
interface IFilters {
  [key: string]: {
    type: string;
    criteria: {
      policy?: 'include' | 'exclude';
      [key: string]: any;
    }[];
  };
}

export enum AudienceVersions {
  default = 'default',
  v4 = 'v4_audience'
}

export enum ERROR_MESSAGES {
  TASK = 'Please add at least one outcome before submitting the Scheduled Task'
}

export const NOT_ALLOWED_EDIT_STATES = [
  SCHEDULEDTASK_STATES.ENDED,
  SCHEDULEDTASK_STATES.SCHEDULED,
  SCHEDULEDTASK_STATES.PROCESSING,
  SCHEDULEDTASK_STATES.FAILED
];
