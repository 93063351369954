import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, DatePicker, message, Select, Table, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import axios from 'axios';
import moment from 'moment-timezone';

import { ITransferRequest, useTransferRequestList } from 'api/bdo/transfer-requests';
import { SearchBar, WrapperPanel } from 'components';
import { FlexContainer } from 'components/Layout/common';
import { PageContent, PageHeader } from 'components/page';
import { useListState, usePointsFormat } from 'hooks';
import API, { downloadFile } from 'utils/request';

import { dateTimeFormat, serverDateFormat } from '../../constants';
import RequestStatusTag from '../components/RequestStatusTag';
import { TransferRequestTypeLabels } from '../utils';

const { RangePicker } = DatePicker;

const tabsItems = [
  { key: 'new', label: 'New' },
  { key: 'in_progress', label: 'In progress' },
  { key: 'completed', label: 'Completed' },
  { key: 'declined', label: 'Declined' },
  { key: 'cancelled', label: 'Cancelled' }
];

const requestTypeOptions = Object.keys(TransferRequestTypeLabels).map(key => ({
  value: key,
  label: TransferRequestTypeLabels[key]
}));

const BDOTransferRequestList = () => {
  const { handleSearch, handleStateChange, handleTableChange, pagination, params } = useListState({
    state: 'new'
  });

  const { formatPoints } = usePointsFormat();

  const [dateRange, setDateRange] = useState(null);
  const [requestType, setRequestType] = useState<string[]>();

  const [startDate, endDate] = dateRange ?? [];
  const start_date = startDate ? moment(startDate).format(serverDateFormat) : null;
  const end_date = endDate ? moment(endDate).format(serverDateFormat) : null;

  const { data, meta, isLoading } = useTransferRequestList({
    ...params,
    start_date,
    end_date,
    request_type: requestType?.join(',')
  });

  const exportSMAC = async () => {
    try {
      const response = await API.get('/v4/dash/custom/bdo/point_transfers/smac_report', {
        params: {
          start_date,
          state: params.state,
          request_type: requestType?.join(',')
        },
        headers: {
          Accept: '*/*'
        }
      });

      let fileName = 'PTRBDORW';
      if (start_date) fileName += `${moment(startDate).format('MMDDyyyy')}`;
      if (!start_date) fileName += `${moment(new Date()).format('MMDDyyyy')}`;

      downloadFile(response.data, `${fileName}.txt`);
    } catch (error) {
      if (!axios.isCancel(error)) {
        message.error(error?.message || 'Something went wrong');
      }
    }
  };

  const generateUMACC = async () => {
    try {
      const response = await API.get('/v4/dash/custom/bdo/point_transfers/report', {
        params: {
          start_date,
          state: params.state,
          request_type: 'BR-CashCred'
        },
        responseType: 'arraybuffer',
        headers: { Accept: '*/*' }
      });

      let fileName = 'UMACC';
      if (start_date) fileName += `-${moment(startDate).format('MM-DD-yy')}`;
      if (!start_date) fileName += `-${moment(new Date()).format('MM-DD-yy')}`;

      downloadFile(response.data, `${fileName}.xlsx`);
    } catch (error) {
      if (!axios.isCancel(error)) {
        message.error(error?.message || 'Something went wrong');
      }
    }
  };

  const downloadCSV = async () => {
    try {
      const response = await API.get('/v4/dash/custom/bdo/point_transfers.csv', {
        params: {
          state: params.state,
          start_date,
          end_date,
          ...params,
          request_type: requestType?.join(',')
        },
        headers: { Accept: '*/*', 'Content-Type': 'text/csv' }
      });

      let fileName = 'transfer-requests';
      if (start_date) fileName += `-from-${start_date}`;
      if (end_date) fileName += `-to-${end_date}`;

      downloadFile(response.data, `${fileName}.csv`);
    } catch (error) {
      if (!axios.isCancel(error)) {
        message.error(error?.message || 'Something went wrong');
      }
    }
  };

  const columns = useMemo(() => {
    const arr: ColumnProps<ITransferRequest>[] = [
      {
        title: 'Request no.',
        key: 'id',
        render: (_, record) => (
          <Link to={`/p/transfer-requests/show/${record.id}`}>{record.id}</Link>
        )
      },
      {
        title: 'Created on',
        key: 'src_brc',
        render: (_, record) => moment(record.created_at).format(dateTimeFormat)
      },
      {
        title: 'Sender’s name',
        key: 'sender',
        width: 250,
        render: (_, record) =>
          [record.properties?.sender_first_name, record.properties?.sender_last_name]
            .filter(Boolean)
            .join(' ')
            .slice(0, 35)
      },
      {
        title: 'Sender’s card no.',
        key: 'sender_card_no',
        render: (_, record) => record.properties?.sender
      },
      {
        title: 'Receiver’s name',
        key: 'receiver',
        width: 250,
        render: (_, record) =>
          [record.properties?.receiver_first_name, record.properties?.receiver_last_name]
            .filter(Boolean)
            .join(' ')
            .slice(0, 35)
      },
      {
        title: 'Destination BRC/SMAC/CC',
        key: 'request_type',
        render: (_, record) => record.properties?.receiver
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (_, record) => formatPoints(Number(record.properties?.amount))
      },
      {
        title: 'Request type',
        key: 'request_type',
        render: (_, record) => TransferRequestTypeLabels[record.request_type]
      },
      ...(params.state !== 'new'
        ? [
            {
              title: 'Status',
              key: 'status',
              render: (_, record) => <RequestStatusTag state={record.state} />
            }
          ]
        : [])
    ];

    return arr;
  }, [params.state]);

  return (
    <>
      <PageHeader title="Transfer requests" />
      <PageContent>
        <WrapperPanel>
          <FlexContainer
            justifyContent="space-between"
            alignItems="flex-start"
            style={{ rowGap: '10px' }}
          >
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
              <SearchBar
                style={{ width: 250 }}
                onSearch={handleSearch}
                placeholder="Search by Request Number"
              />
              <Select
                placeholder="Filter by request type"
                allowClear
                style={{ minWidth: 300, maxWidth: 800 }}
                size="large"
                mode="multiple"
                onChange={value => {
                  setRequestType(value);
                }}
                options={requestTypeOptions}
                value={requestType}
              />
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <RangePicker value={dateRange} onChange={setDateRange} size="large" />
              <Button size="large" onClick={exportSMAC}>
                Export SMAC Report
              </Button>
              <Button size="large" onClick={generateUMACC}>
                Generate UMACC
              </Button>
              <Button size="large" onClick={downloadCSV}>
                Download CSV
              </Button>
            </div>
          </FlexContainer>
          <Tabs
            activeKey={params.state}
            onChange={handleStateChange}
            tabBarStyle={{ marginBottom: 0 }}
            items={tabsItems}
          />
          <Table<ITransferRequest>
            rowKey="id"
            dataSource={data}
            columns={columns}
            pagination={{
              ...pagination,
              total: meta?.total_count
            }}
            loading={isLoading}
            onChange={handleTableChange}
          />
        </WrapperPanel>
      </PageContent>
    </>
  );
};

export default BDOTransferRequestList;
