export const getFullMenu = () => [
  {
    key: 'language_selector',
    url: '/p/language_selector',
    svg: 'language_selector',
    description: 'Languages'
  },
  {
    key: 'business_intelligence',
    url: '/p/business_intelligence/overview',
    svg: 'dashboard',
    description: 'Dashboard'
  },
  {
    key: 'quicksight',
    url: '/p/quicksight',
    svg: 'dashboard',
    description: 'Analytics'
  },
  {
    key: 'reports',
    url: '/p/reports',
    svg: 'reports',
    description: 'Reports'
  },
  {
    key: 'marketplace',
    url: '/p/marketplace',
    svg: 'marketplace',
    description: 'Marketplace'
  },
  {
    key: 'rewards',
    url: '/p/rewards',
    svg: 'rewards',
    description: 'Rewards'
  },
  {
    key: 'inventories',
    url: '/p/inventories',
    svg: 'inventory',
    description: 'Inventory'
  },
  {
    key: 'catalogs',
    url: '/p/catalogues',
    svg: 'catalogues',
    description: 'Catalogues'
  },
  {
    key: 'campaigns',
    url: '/p/campaigns',
    svg: 'campaigns',
    description: 'Campaigns'
  },
  {
    key: 'badges',
    url: '/p/badges',
    svg: 'badges',
    description: 'Badges'
  },
  {
    key: 'leaderboards',
    url: '/p/leaderboards',
    svg: 'leaderboards',
    description: 'Leaderboards'
  },
  {
    key: 'loyalties',
    url: '/p/loyalty',
    svg: 'loyalty',
    description: 'Loyalty'
  },
  {
    key: 'audiences',
    url: '/p/audiences',
    svg: 'audience',
    description: 'Audience'
  },
  {
    key: 'transaction_rules',
    url: '/p/rules',
    svg: 'rules',
    description: 'Rules'
  },
  {
    key: 'merchants',
    url: '/p/merchants',
    svg: 'merchant',
    description: 'Merchants'
  },
  {
    key: 'customer_management',
    url: '/p/customers',
    svg: 'customers',
    description: 'Customers'
  },
  {
    key: 'bulk_actions',
    url: '/p/bulk_actions',
    svg: 'bulk_actions',
    description: 'Bulk Actions'
  },
  {
    key: 'partners',
    url: '/p/partners',
    svg: 'partner',
    description: 'Partners'
  },
  {
    key: 'microsites',
    url: '/p/microsite',
    svg: 'phone',
    description: 'Microsite'
  },
  {
    key: 'transfer_requests',
    url: '/p/transfer-requests',
    svg: 'transfers',
    description: 'Transfer requests'
  },
  {
    key: 'settings',
    url: '/p/settings/general/media',
    svg: 'settings',
    description: 'Settings'
  },
  {
    key: 'scheduled_tasks',
    url: '/p/scheduled-tasks',
    svg: 'scheduled_tasks',
    description: 'Scheduled Task'
  }
];

export const getFilteredMenu = (permissions = {}) => {
  const permissionKeys = Object.keys(permissions);
  return getFullMenu().filter(
    item =>
      permissionKeys.includes(item.key) &&
      !permissions?.[item.key]?.restricted_actions?.includes('index')
  );
};
