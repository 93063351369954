import React from 'react';
import { Select, SelectProps } from 'antd';

import { useTagList } from 'api/tags';

interface TagSelectProps extends SelectProps<number[]> {}

export const TagSelect = (props: TagSelectProps) => {
  const { data: tags = [] } = useTagList();

  const filterOption = (input: string, option) =>
    (option?.label ?? '').toString().toLowerCase().includes(input?.toLowerCase());
  return (
    <Select
      filterOption={filterOption}
      showArrow
      showSearch
      {...props}
      value={props.value}
      onBlur={e => e.preventDefault()}
      options={tags?.map(item => ({ value: item.id, label: item.name }))}
    />
  );
};

export default TagSelect;
