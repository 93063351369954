// GLOBAL constnats
import _startCase from 'lodash/startCase';
import moment from 'moment-timezone';

//@ts-ignore
export const API_ROOT = window.__API_URL__;
//@ts-ignore
export const IS_PF = window.__IS_PF__;
export const METABASE_API_ROOT = 'https://metabase-api.perxtech.io';

export const PERX_MAIL_DOMAIN = '@perxtech.com';

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIMEZONE_FORMAT = '(Z)';
export const DB_RECORD_DATETIME_FORMAT = 'DD MMM YYYY HH:mm (Z)';

export const DEFAULT_PAGE_SIZE = 25;
export const MID_PAGE_SIZE = 10;
export const SMALL_PAGE_SIZE = 5;

export const MAX_INTEGER_VALUE = 2147483647; // 4 bit signed integer max

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
// TODO: extend the env config in the build process instead of doing this
export const IS_STAGING =
  IS_DEVELOPMENT ||
  ((window.location.hostname === 'dashboard.perxtech.io' ||
    window.location.hostname.startsWith('feature')) &&
    process.env.NODE_ENV === 'production');
export const IS_PRODUCTION = !IS_STAGING && process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT_ONLY = IS_DEVELOPMENT && !IS_STAGING && !IS_PRODUCTION;
export const HIDE_ON_PRODUCTION = !IS_PRODUCTION;

export const POINTS_BURNED = 'Points Burned';

export const DAY_KEY_VALUE = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday'
};

export const DAYS = Object.entries(DAY_KEY_VALUE).map(([id, name]) => ({ id: +id, name }));

export const TIMEZONES = moment.tz
  .names()
  .map(_ => ({
    value: _,
    offsetLabel: moment.tz(moment(), _).format('Z'),
    label: _startCase(_),
    offset: moment.tz(moment(), _).utcOffset()
  }))
  .sort((a, b) => a.offset - b.offset);

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const TENANTS = {
  STARHUB: 'starhub',
  MERCK: 'merck',
  ABENSON: 'abenson',
  UOB: 'uob_uob',
  COMMBANK: 'commbank',
  ZEAL: 'zeal',
  RAZER: 'razer',
  ZAW: 'prod_zaw',
  DAIICHI: 'dlvn',
  HSBC: 'hsbc_hsbc',
  HSBCVN: 'hsbcvn',
  GLOBEATHOME: 'globeathome',
  GLONEONE: 'globeone',
  HOOLAH: 'hoolah',
  HOOLAH_HK: 'hoolah_hk',
  HOOLAH_MY: 'hoolah_my',
  FSI: 'fsi',
  TELCO: 'telco',
  RETAIL: 'retail',
  INSURANCE: 'insurance',
  FINTECH: 'fintech',
  RUSH: 'rush',
  CWC: 'cwc',
  SIAM_PIWAT: 'siampiwat',
  SIAM_PIWAT_PREPROD: 'spw_preprod',
  TRANSCYCLE: 'transcycle',
  TRANSCYCLE_PREPROD: 'transcycle_preprod',
  CS: 'cs',
  GENERIC: 'generic',
  AFFIRM: 'affirm',
  KISSHT: 'kissht'
};

export const THEMED_TENANTS = [TENANTS.ZEAL, TENANTS.RUSH, TENANTS.CWC, TENANTS.AFFIRM];

export const DEMO_TENANTS = [
  'perx_sales',
  'perx_sales2',
  'perx_sales3',
  'perx_sales4',
  'perx_sales5',
  'perx_sales6',
  'perx_sales7',
  'perx_sales8',
  'perx_sales9',
  'perx_sales10',
  'generic',
  'salesdemo',
  'partners',
  TENANTS.FSI,
  TENANTS.TELCO,
  TENANTS.RETAIL,
  TENANTS.INSURANCE,
  TENANTS.FINTECH
];

export const TENANT_DISPLAY_NAME = {
  [TENANTS.STARHUB]: 'Starhub',
  [TENANTS.MERCK]: 'Merck',
  [TENANTS.ABENSON]: 'Abenson',
  [TENANTS.UOB]: 'UOB',
  [TENANTS.ZEAL]: IS_PRODUCTION ? 'Zeal' : 'POSable',
  [TENANTS.RAZER]: 'Razer',
  [TENANTS.DAIICHI]: 'Daiichi',
  [TENANTS.HSBCVN]: 'HSBC VN',
  [TENANTS.HSBC]: 'HSBC PH',
  [TENANTS.GLOBEATHOME]: 'Globe At Home',
  [TENANTS.GLONEONE]: 'Globe One',
  [TENANTS.HOOLAH]: 'HOOLAH SG',
  [TENANTS.HOOLAH_HK]: 'HOOLAH HK',
  [TENANTS.HOOLAH_MY]: 'HOOLAH MY',
  [TENANTS.COMMBANK]: 'CommBank',
  [TENANTS.SIAM_PIWAT]: 'Siam Piwat',
  [TENANTS.AFFIRM]: 'Affirm'
};

export const LIVE_TENANTS = [
  TENANTS.ABENSON,
  TENANTS.STARHUB,
  TENANTS.UOB,
  TENANTS.MERCK,
  TENANTS.ZEAL,
  TENANTS.RAZER,
  TENANTS.ZAW,
  TENANTS.GLOBEATHOME,
  TENANTS.GLONEONE,
  TENANTS.HSBC,
  TENANTS.HSBCVN,
  TENANTS.HOOLAH,
  TENANTS.HOOLAH_HK,
  TENANTS.HOOLAH_MY,
  TENANTS.COMMBANK
];

// notifications
export const DURATION_TIMER = 2;

// Errors
export const ERRORS_DURATION_TIMER = 3000;
export const ERRORS = {
  REQUIRED: 'This field is required.',
  END_TIME_ERROR: 'End date cannot be before start date.',
  NUMBER_SIZE_MORE_ZERO: 'Value must be more then zero.',
  NUMBER_SIZE_MORE_OR_ZERO: 'Value must be greater than or equal to zero.',
  WRONG_TYPE: 'This field is wrong',
  APPROVE_OWN_REQUEST: 'You cannot approve your own request.'
};

// Translations
export const fieldsWithTranslations = [
  'name',
  'subtitle',
  'description',
  'terms_and_conditions',
  'steps_to_redeem'
];

export interface Feature {
  key: string;
  label: string;
  category?: string;
  subtext?: string;
  disabled?: boolean;
}

const FEATURE_SUBTEXT = {
  QA: 'Unsupported. Only accepting feedback from QA. Personally ensure what is shown is working before demo.',
  IN_DEVELOPMENT:
    'Unsupported. Not accepting feedback from anyone. Expect this to not work, enable at own risk.',
  DEPRECATED: 'Deprecated legacy feature pending review for removal.'
};

type FeatureMap = Record<string, Feature>;

/**
 * helper function which infers keys and restricts values to Feature type
 * relies on the tenant config features
 * source: https://stackoverflow.com/a/54598743/5280598
 */
const asFeatureFlagTypes = <T extends FeatureMap>(et: { [K in keyof T]: T[K] }) => et;

/**
 * Let's keep items in this dictionary sorted by key.
 *
 * It will help us to
 * - quicker find a desired item
 * - make more consistent naming for new items
 * - prevent merge conflicts when there are several pull requests
 * with new feature flags at the end of the list
 */
export const FEATURES = asFeatureFlagTypes({
  ACCORDION_DESCRIPTION_TEXT_EDITOR_IN_REWARDS: {
    key: 'accordion_description_text_editor_in_rewards',
    label: 'Accordion description text editor in reward',
    subtext: FEATURE_SUBTEXT.QA
  },
  ALPHABET_VOUCHER_CODE: {
    key: 'alphabet_voucher_code_format',
    label: 'Voucher Code Format - Alphabet',
    subtext: 'Enables alphabet voucher code format option.'
  },
  ALPHANUMERIC_VOUCHER_CODE: {
    key: 'alphanumeric_voucher_code_format',
    label: 'Voucher Code Format - Alphanumeric',
    subtext: 'Enables alphanumeric voucher code format option.'
  },
  ALTERNATE_WYSIWYG: {
    key: 'alt_wysiwyg',
    label: 'Alternate Rich Text editor',
    subtext: 'Switches between different Rich Text editors in forms.'
  },
  AUDIENCE: {
    key: 'audience',
    label: 'Audience',
    subtext: 'Enables the Audience module. ' + FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  AUDIENCE_DEMOGRAPHICS_SELECTION: {
    key: 'audience_demographics_selection',
    label: 'Audience Demographics Selection',
    subtext: 'Enables audience demographics filter selection. Requires custom Pers backend setup'
  },
  AUDIENCE_EMAIL_TAGS: {
    key: 'audience_email_tags',
    label: 'Audience Email Tags',
    subtext: 'Enables audience email tags filter selection. Requires email tags setup from customer'
  },
  AUDIT_LOG: {
    key: 'audit_log',
    label: 'Audit Log',
    subtext: 'Enables the display of Audit logs in applicable pages'
  },
  // AUTO_ENROL_USERS: {
  //   key: 'auto_enrol_users',
  //   label: 'Auto Enrol Users',
  //   subtext: 'Enables auto enrollment feature in campaign module.'
  // },
  AUTO_FILL_STAMPS: {
    key: 'auto_fill_stamps',
    label: 'Auto Fill Stamp',
    subtext: 'Enables auto enrollment feature in stamp campaign module.'
  },
  AUTO_REDEEM_INSTANT_OUTCOME_TRANSACTION: {
    key: 'auto_redeem_instant_outcome_transaction',
    label: 'Auto Redeem Instant Outcome Transaction',
    subtext:
      'Enables auto redeem instant outcome feature for instant reward to be issued just like any other stamp/progress campaign.'
  },
  BADGES: {
    key: 'badges',
    label: 'Badges',
    subtext: 'Enables Badges module where you can create badges to be used as outcomes.'
  },
  BRANDS: {
    key: 'brands',
    label: 'Brands',
    subtext: FEATURE_SUBTEXT.DEPRECATED
  },
  BULK_ACTIONS: {
    key: 'bulk_actions',
    label: 'Bulk Actions',
    subtext: 'Currently does not control anything'
  },
  BULK_ACTIONS_LOG: {
    key: 'bulk_actions_log',
    label: 'Bulk Actions - Log Files',
    subtext: 'Enables downloading log files for bulk uploads'
  },
  BULK_ACTIONS_PROCESSED_ROWS: {
    key: 'bulk_actions_processed_rows',
    label: 'Bulk Actions - Processed Rows',
    subtext: 'Shows processed rows column in bulk actions list table.'
  },
  BULK_ACTIONS_UPLOAD_USERS: {
    key: 'bulk_actions_upload_users',
    label: 'Bulk Actions Upload Users',
    subtext: 'Enables bulk actions upload users'
  },
  BULK_ACTIONS_ADD_MARCHANT_NAME: {
    key: 'add_merchant_name_in_bulk_upload_points',
    label: 'Add Merchant Name In Bulk Upload Points',
    subtext: 'Enables Add Merchant name for Bulk Upload'
  },
  BULK_UPDATE_VOUCHERS_EXPIRY: {
    key: 'bulk_update_voucher_expiry',
    label: 'Bulk Actions - Update Vouchers Expiry Date'
  },
  BUSINESS_INTELLIGENCE: {
    key: 'business_intelligence',
    label: 'Business Intelligence',
    subtext: 'Enables Analytics module'
  },
  CAMPAIGN_DUPLICATION: {
    key: 'campaign_duplication',
    label: 'Campaign Duplication',
    subtext:
      'Enables action to duplicate campaign from the campaign list page. ' + FEATURE_SUBTEXT.QA
  },
  CAMPAIGNS: {
    key: 'campaigns',
    label: 'BI - Campaigns',
    subtext: 'Displays Campaigns tab in Analytics module'
  },
  CATALOGS: {
    key: 'catalogs',
    label: 'Catalogues',
    subtext: 'Enables the Catalogues module and all related sections in other modules'
  },
  CATALOGUE_AUDIENCE: {
    key: 'catalogue_audience',
    label: 'Catalogue Audience',
    subtext: 'Enables setting Audience filters during Catalogue creation/editing'
  },
  CATALOGUE_ITEMS: {
    key: 'catalogue_items',
    label: 'Catalogue Items',
    subtext: 'Enables catalogue item card in catalogue show page.'
  },
  CATALOGUE_TAG_MERCHANT: {
    key: 'catalogue_tag_merchant',
    label: 'Catalogue Tag Merchant'
  },
  CATALOGUE_TERMS_CONDITIONS: {
    key: 'catalogue_terms_conditions',
    label: 'Catalogue Terms and Conditions',
    subtext: 'Enables Terms and Conditions for Catalogue creation/editing'
  },
  CHOOSE_YOUR_REWARD: {
    key: 'choose_campaign_outcome',
    label: 'Choose Your Reward',
    subtext: 'Enables the Choose Your Reward feature in for campaign outcomes.'
  },
  CREATE_POINTS: {
    key: 'create_points',
    label: 'Bulk Upload - Create Points',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  CTA_BUTTON_EXTERNAL_LINK: {
    key: 'cta_button_external_link',
    label: 'Campaign CTA Button External Link',
    subtext: 'Enables campaign CTA button external link text input'
  },
  CUSTOM_APIS: {
    key: 'custom_apis',
    label: 'Custom APIS',
    subtext: 'Enables Custom API settings in Settings -> General'
  },
  DATA_RETENTION_POLICY: {
    key: 'customer_anonymization_policy',
    label: 'Data Retention Policy'
  },
  CUSTOM_FIELDS: {
    key: 'custom_fields',
    label: 'Custom Fields',
    subtext:
      'Custom Fields is an enterprise escape hatch for when a tenant want to pass data on the API.'
  },
  CURRENCY_CONVERSION_RATE: {
    key: 'currency_conversion_rate',
    label: 'Currency Conversion Rate',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  CUSTOM_FIELDS_TEXTAREA: {
    key: 'custom_fields_textarea',
    label: 'Custom Fields Teaxtarea',
    subtext: 'Allows setting multi-line values for custom fields.'
  },
  CUSTOMER_BULK_UPLOAD_TRIGGER_POST_CREATE_ACTIONS: {
    key: 'customer_bulk_upload_trigger_post_create_actions',
    label: 'Customer Bulk Upload Trigger Create Actions',
    subtext:
      'Enrolls users to loyalty programs and creates sing up event when user uploaded via v4/dash/file_imports enpoint'
  },
  CUSTOMER_ENROL_UNENROL_CAMPAIGNS: {
    key: 'customer_enrol_unenrol_campaigns',
    label: 'Customer - Enrol/Un-enrol in Campaigns'
  },
  // CUSTOMERS: { key: 'customer_management' },
  CUSTOMERS_INITIAL_TAB: {
    key: 'customers_initial_tab',
    label: 'Customers Initial Tab'
  },
  ENROLL_ELIGIBLE_USERS_TO_CAMPAIGNS: {
    key: 'enroll_eligible_users_to_campaigns',
    label: 'Allow Auto Enrol with Campaign Duration Mechanics Enabled',
    subtext:
      'Allows Campaigns with Auto Enrol ON and Campaign Duration Mechanics ON. Can cause performance problems. Do not Enable except for BTPN without Product & Engineering approval'
  },
  ENROLLABLE_UNTIL: {
    key: 'enrollable_until',
    label: 'Enrollable until',
    subtext: 'Enables setting of enrolling period end date'
  },
  EARLY_DISPLAY_CAMPAIGNS: {
    key: 'early_display_campaigns',
    label: 'Early Display Campaigns',
    subtext: 'Enables campaigns to be displayed before the start date'
  },
  GAMES_VARIABLE_POINT: {
    key: 'games_variable_point',
    label: 'Variable Point as an outcome for games',
    subtext: FEATURE_SUBTEXT.QA
  },
  GENERATE_PF_DAILY_TRANSACTION_REPORT: {
    key: 'generate_pf_daily_transaction_report',
    label: 'Daily Transaction Report (SARG)',
    subtext: 'Enable to trigger the “Daily Transaction Report (SARG)"'
  },
  GENERATE_USER_IDENTIFIER: {
    key: 'autogenerate_random_user_identifier',
    label: 'Generate Random User Identifier',
    subtext: 'Enables the feature to autogenerate random user identifier'
  },
  SINGLE_GL_ACCOUNT_LOOKUP_TABLE: {
    key: 'single_gl_account_lookup_table',
    label: 'Single Select gl_account in Rule, Customer, Campaign, Loyalty',
    subtext: 'Single Select gl_account in Rule, Customer, Campaign, Loyalty'
  },
  GL_ACCOUNT_LOOKUP_TABLE: {
    key: 'gl_account_lookup_table',
    label: 'Multiselect gl_account in Rule, Customer, Campaign, Loyalty',
    subtext: 'Multiselect gl_account in Rule, Customer, Campaign, Loyalty'
  },
  QUIZ_VARIABLE_POINT: {
    key: 'quiz_variable_point',
    label: 'Variable Point as an outcome for quiz',
    subtext: FEATURE_SUBTEXT.QA
  },
  HEXANUMERIC_VOUCHER_CODE: {
    key: 'hexanumeric_voucher_code_format',
    label: 'Voucher Code Format - Hexanumeric',
    subtext: 'Enables hexanumeric voucher code format option.'
  },
  HIDE_UPLOAD_POINT_CSV_DETAILS: {
    key: 'hide_upload_point_csv_details',
    label: 'Hide Upload Point CSV Details',
    subtext: 'Enable Will Hide Upload Point CSV Details'
  },
  // SHOW_INSTANT_REWARDS: {
  //   key: 'show_instant_rewards',
  //   label: 'Campaign Type - Instant Rewards',
  //   subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  // },
  INSTANT_REWARD_CAMPAIGN: {
    key: 'instant_reward_campaign',
    label: 'Instant Reward Campaign',
    subtext: FEATURE_SUBTEXT.QA
  },
  INVENTORY: {
    key: 'inventories',
    label: 'Inventory',
    subtext: 'Enables the Inventories module'
  },
  LABELS: {
    key: 'labels',
    label: 'Labels',
    subtext: 'Enables the Labels module and all related sections in other modules'
  },
  LANDING_PAGE_FOR_GAMES: {
    key: 'landing_page_for_games',
    label: 'Landing page for game campaigns',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  LANDING_PAGE_FOR_STAMPS: {
    key: 'landing_page_for_stamps',
    label: 'Landing page for stamp campaigns',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  REFERRAL_ACTION_MECHANICS: {
    key: 'referral_action_mechanics',
    label: 'Referral Action Mechanics',
    subtext: 'Enable the feature to view action mechanics section on referral campaign.'
  },
  CAMPAIGN_LEVEL_BI: {
    key: 'campaign_level_bi',
    label: 'Campaign Level BI',
    subtext: 'Enable the feature to view insights on each campaign.'
  },
  LEADERBOARDS: {
    key: 'leaderboards',
    label: 'Leaderboards',
    subtext: 'Enables Leaderboards module.'
  },
  LIVE_BI_DATA: {
    key: 'live_bi_data',
    label: 'Live BI Data',
    subtext: 'Switch the analytics data pipeline to real data from dummy data'
  },
  LOCALIZATION_SETTINGS: {
    key: 'localization_settings',
    label: 'Localization Settings',
    subtext: 'Enables Localization settings in Settings -> General' + FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  LOCATION_BASED_RULE: {
    key: 'location_based_rule',
    label: 'Location Based Rule',
    subtext: 'Enables location based rule in progress campaign'
  },
  LOOKUPS: {
    key: 'lookups',
    label: 'Lookups',
    subtext: 'Enables Lookups in setting.'
  },
  LOOKUP_TABLE_VALUES: {
    key: 'lookup_table_value',
    label: 'Value Lookup table',
    subtext: 'Enables Changing Value for Lookup table.'
  },
  UPDATE_LOOKUPS: {
    key: 'lookup_table_value_update',
    label: 'Update Lookup table value',
    subtext: 'Enables Update Lookup table value.'
  },
  LANGUAGE_SELECTOR: {
    key: 'language_selector',
    label: 'Languages',
    subtext:
      'Enables support for multiple languages throughout the app in German, Italian, French and expanding.'
  },
  LOYALTIES: {
    key: 'loyalties',
    label: 'Loyalties',
    subtext: 'Enables the Loyalties module and all related sections in other modules'
  },
  LOYALTY_AUDIENCE: {
    key: 'loyalty_audience',
    label: 'Loyalty Audience',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  LOYALTY_EXTEND_MEMBERSHIP: {
    key: 'loyalty_extend_membership',
    label: 'Loyalty Extend Membership'
  },
  LOYALTY_POINTS_DECIMALS: {
    key: 'loyalty_points_decimals',
    label: 'Loyalty Points Decimals',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  LOYALTY_POINTS_LIMIT: {
    key: 'loyalty_points_limit',
    label: 'Loyalty Points Limit Per User',
    subtext: 'Allows to configure points limit per user'
  },
  LOYALTY_POINTS_PEER_TO_PEER_TRANSFER_LIMIT: {
    key: 'loyalty_points_peer_to_peer_transfer_limit',
    label: 'Loyalty Points Peer to Peer Transfer Limit',
    subtext: 'Enables setting for limit on points peer to peer Transfer'
  },
  LOYALTY_POINTS_TRANSFER: {
    key: 'loyalty_points_transfer',
    label: 'Loyalty Points Transfer',
    subtext: 'Enables settings for exchange rate between Loyalty programs'
  },
  LOYALTY_POINTS_TRANSFER_LIMITS: {
    key: 'loyalty_points_transfer_limits',
    label: 'Loyalty Points Transfer Limits',
    subtext: 'Enables limits settings for points transfer flow'
  },
  LOYALTY_REVIEW_CYCLES: {
    key: 'loyalty_review_cycles',
    label: 'Loyalty Review Cycles'
  },
  LOYALTY_RULE_BIRTHDAY_CHECKBOX: {
    key: 'loyalty_rule_birthday_checkbox',
    label: 'Loyalty Rule Birthday Checkbox'
  },
  LOYALTY_RULE_TRANSACTION_PROPERTY: {
    key: 'loyalty_rule_transaction_property',
    label: 'Loyalty Rule Transaction Property'
  },
  LOYALTY_RULE_TRANSACTION_TYPE: {
    key: 'loyalty_rule_transaction_type',
    label: 'Loyalty Rule Transaction Type'
  },
  LOYALTY_UPLOAD_PHOTO: {
    key: 'loyalty_upload_photo',
    label: 'Loyalty Upload Photo'
  },
  MAKER_CHECKER_CUSTOMER_MANAGEMENT: {
    key: 'maker_checker_customer_management',
    label: 'Maker Checker Customer Management',
    subtext: 'Maker Checker Customer Management'
  },
  MAKER_CHECKER_CUSTOMER_BULK_ACTIONS: {
    key: 'maker_checker_bulk_actions',
    label: 'Maker Checker Bulk Actions',
    subtext: 'Maker Checker Bulk Actions'
  },
  MAKER_CHECKER_REWARD: {
    key: 'maker_checker_rewards',
    label: 'Maker Checker Reward',
    subtext: 'Enable Maker Checker for Rewards'
  },
  MAKER_CHECKER_CAMPAIGN: {
    key: 'maker_checker_campaigns',
    label: 'Maker Checker Campaign',
    subtext: 'Enable Maker Checker for Campaigns'
  },
  MAKER_CHECKER_RULE: {
    key: 'maker_checker_rules',
    label: 'Maker Checker Rule',
    subtext: 'Enable Maker Checker for Rules'
  },
  MEDIA_TAG: {
    key: 'media_tag',
    label: 'Media Tag',
    subtext: 'Enables media tag in rule builder.'
  },
  MERCHANT_PINS_BULK_UPLOAD: {
    key: 'merchant_pins_bulk_upload',
    label: 'Merchant Pins Bulk Upload',
    subtext: 'Enables merchant pins bulk upload.'
  },
  MERCHANT_PORTAL: {
    key: 'merchant_portal',
    label: 'Merchant Portal',
    subtext: 'Changes display of Merchants in list and show page based on Merchant approval status.'
  },
  MERCHANT_USERS: {
    key: 'merchant_users',
    label: 'Merchant Users',
    subtext: 'Enables the creation of Merchant users in the Merchant Show page'
  },
  MERCHANTS: {
    key: 'merchants',
    label: 'Merchants',
    subtext:
      'Makes Merchants permissions controls available in User->Roles and also enables Merchant page in Analytics.'
  },
  MICROSITE: {
    key: 'microsite',
    label: 'Microsite Configuration',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  MULTIPLE_REWARD_ISSUANCE: {
    key: 'multiple_reward_issuance',
    label: 'Multiple Reward Issue',
    subtext: 'Enables multiple reward issue feature in reward.'
  },
  NOTIFICATION_PREFERENCES: {
    key: 'notification_preferences',
    label: 'Notification Preferences',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  NUMERIC_VOUCHER_CODE: {
    key: 'numeric_voucher_code_format',
    label: 'Voucher Code Format - Numeric',
    subtext: 'Enables numeric voucher code format option.'
  },
  OBJECT_LEVEL_PERMISSION: {
    key: 'object_level_permission',
    label: 'Object Level Permissions',
    subtext: 'Enables Access Groups settings for users.'
  },
  OUTCOME_POPUP_MESSAGE_SETTINGS: {
    key: 'outcome_popup_message',
    label: 'Outcome pop-up message settings for progress, quest and stamp campaigns',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  OWNER_TAG: {
    key: 'object_owner',
    label: 'Owner Tag',
    subtext: 'Enables Owner Tag for Campaigns, Rules, Rewards, etc.'
  },
  PENDO_CONFIGURATION: {
    key: 'pendo_configuration',
    label: 'Pendo Configuration',
    subtext: 'Enables pendo configuration in portal'
  },
  PLINKO_GAME: {
    key: 'plinko',
    label: 'Plinko Game Type',
    subtext: FEATURE_SUBTEXT.QA
  },
  POS_TRIGGER_POST_CREATE_ACTIONS: {
    key: 'pos_trigger_post_create_actions',
    label: 'POS Trigger Create Actions',
    subtext:
      'Enrolls users to loyalty programs and creates sing up event when user uploaded via v4/pos/user_account enpoint'
  },
  PRICE_IDENTIFIER: {
    key: 'price_identifier',
    label: 'Price Identifier',
    subtext: 'Enables a custom identifier for reward selling price'
  },
  PRIVATE_REWARDS: {
    key: 'private_rewards',
    label: 'Private Rewards',
    subtext: 'Enables private reward type'
  },
  PRIZE_SETS: {
    key: 'prize_set_outcomes',
    label: 'Prize Sets as Outcome',
    subtext: FEATURE_SUBTEXT.QA
  },
  PROGRESS_CAMPAIGN: {
    key: 'progress_campaign',
    label: 'Campaign Type - Progress Campaign',
    subtext: FEATURE_SUBTEXT.QA
  },
  PROGRESS_RULE_TRIGGER: {
    key: 'progress_rule_trigger',
    label: 'Progress Rule Trigger',
    subtext: 'Enables a progress rule trigger option in campaign trigger of rule'
  },
  PROVISION_RATE: {
    key: 'provision_rate',
    label: 'Provision Rate',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  QUEST_CAMPAIGNS: {
    key: 'quest_campaigns',
    label: 'Quest Campaigns'
  },
  QUICKSIGHT: {
    key: 'quicksight',
    label: 'QuickSight',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  QUIZ_CAMPAIGNS: {
    key: 'quiz',
    label: 'Quiz Campaigns',
    subtext: 'Enables quiz campaigns.'
  },
  RAFFLE_CAMPAIGNS: {
    key: 'raffle_campaigns',
    label: 'Campaign Type - Raffle',
    subtext: 'Enables Campaign type - Raffle'
  },
  RANDOMIZE_REWARDS_PROBABILITY: {
    key: 'randomize_rewards_probability',
    label: 'Randomize Rewards Probability',
    subtext: 'Enables randomization of reward probabilities for campaign outcomes'
  },
  RECURRING_CAMPAIGNS: {
    key: 'recurring_campaigns',
    label: 'Recurring Campaigns',
    subtext: 'Enables settings for campaigns to run only on certain days and timings.'
  },
  RECURRING_REWARDS: {
    key: 'recurring_rewards',
    label: 'Recurring Rewards',
    subtext: 'Enables settings for rewards to be displayed only on certain days and timings.'
  },
  REPORTS: {
    key: 'reports',
    label: 'Reports'
  },
  REWARD_ACTIVATION_DATE: {
    key: 'reward_activation_date',
    label: 'Reward Activation Date',
    subtext: 'Enables setting the activation date for a reward.'
  },
  REWARD_BUDGETING: {
    disabled: true,
    key: 'reward_budgeting',
    label: 'Reward Budgeting',
    subtext: 'Backend assistance required to enable this feature'
  },
  REWARD_CAMPAIGN: {
    key: 'reward_campaign',
    label: 'Campaign Type - Reward Campaign',
    subtext: FEATURE_SUBTEXT.QA
  },
  REWARD_COST: {
    key: 'rewards_cost',
    label: 'Rewards Cost Input'
  },
  REWARD_LEGACY_AUDIENCE: {
    key: 'reward_legacy_audience',
    label: 'Rewards Legacy Inline Audience',
    subtext: 'Rewards Inline Audience - Where can this be displayed?'
  },
  REWARD_REDEMPTION_OUTCOMES: {
    key: 'reward_redemption_outcomes',
    label: 'Reward Redemption Outcomes',
    subtext: '"Loyalties" flag must also be enabled for this to take effect'
  },
  REWARD_SELLING_PRICE_CASH: {
    key: 'reward_selling_price_cash',
    label: 'Reward Selling Price Cash',
    subtext: 'Enables "cash" input in the selling price modal in rewards module.'
  },
  REWARD_TYPE_FILTER_TAB: {
    key: 'reward_type_filter_tab',
    label: 'Rewards Type Filter Tab',
    subtext: 'Enables the type filter - "Private" and "System" - tabs in Rewards list table.'
  },
  REWARDS: {
    key: 'rewards',
    label: 'BI - Rewards',
    subtext: 'Displays Rewards tab in Analytics module'
  },
  REWARDS_ARCHIVING: {
    key: 'rewards_archiving',
    label: 'Rewards Archiving',
    subtext: 'Rewards Archiving Allows End Users to move vouchers to archived.'
  },
  REWARDS_AUDIENCE: {
    key: 'reward_audience',
    label: 'Rewards Audience'
  },
  REWARDS_DISPLAY_EVEN_WHEN: {
    key: 'rewards_display_even_when',
    label: 'Rewards "Display even when limit is reached" settings'
  },
  REWARDS_FORM_CATALOG_ASSIGNMENT: {
    key: 'rewards_form_catalog_assignment',
    label: 'Rewards Form Catalog Direct Assignment',
    subtext: FEATURE_SUBTEXT.DEPRECATED
  },
  REWARDS_GIFTING: {
    key: 'rewards_gifting',
    label: 'Rewards Gifting',
    subtext: 'Rewards Gifting Allows End Users to Give Each Other Vouchers.'
  },
  REWARDS_LIST_STOCK_FILTERS: {
    key: 'rewards_list_stock_filters',
    label: 'Rewards List Stock Filters',
    subtext: 'Shows Selling Fast (<10% left) and Sold Out tabs in rewards list.'
  },
  REWARDS_MARKETPLACE: {
    key: 'rewards_marketplace',
    label: 'Rewards Marketplace',
    subtext: 'Enables Rewards Marketplace in the main sidebar navigation'
  },
  REWARDS_REDEMPTION_COUNTDOWN: {
    key: 'rewards_redemption_countdown',
    label: 'Rewards Redemption Offline Countdown',
    subtext: FEATURE_SUBTEXT.DEPRECATED
  },
  REWARD_REDEMPTION_LIMIT: {
    key: 'reward_total_redemption_limit',
    label: 'Enable Total Reward Redemption Limit',
    subtext: 'Enables reward redemption limit field in reward mechanics'
  },
  REWARDS_BULK_UPLOAD: {
    key: 'reward_upload',
    label: 'Rewards Bulk Upload',
    subtext: 'Enables bulk upload for rewards.'
  },
  REWARDS_TRANSACTION_ISSUED_TAB: {
    key: 'rewards_transaction_issued_tab',
    label: 'Rewards Legacy Transaction Issued Tab',
    subtext: FEATURE_SUBTEXT.DEPRECATED
  },
  REWARDS_VALIDITY_PERIOD_END_OF_MONTH: {
    key: 'rewards_validity_period_end_of_month',
    label: 'Rewards Mechanics Validity Period End of the Month'
  },
  RISK_DISCLAIMER: {
    key: 'risk_disclaimer',
    label: 'Risk Disclaimer Field',
    subtext: 'Enables risk disclaimer field in Campaign and Reward creation'
  },
  RULE_BALANCE_COL: {
    key: 'rule_balance_col',
    label: 'Rule Balance Column',
    subtext: 'Enables the balance column in rule list table.'
  },
  RULE_EDITING: {
    key: 'rule_editing',
    label: 'Allow editing of rules'
  },
  RULE_GROUP_CAMPAIGN: {
    key: 'rule_group_campaign',
    label: 'Campaign Type - Multiple Rules',
    subtext: FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  RULE_TRIGGER_LIMIT_ON_CAMPAIGN_LEVEL: {
    key: 'rule_trigger_limit_on_campaign_level',
    label: 'Rule Trigger Limit on Campaign/Loyalty Level',
    subtext: 'Enables rule trigger limit (on campaign/loyalty level)'
  },
  RULES: {
    key: 'transaction_rules',
    label: 'Rules',
    subtext: 'Currently does not control anything'
  },
  SETTINGS: {
    key: 'settings',
    label: 'Settings',
    subtext: 'Currently does not control anything'
  },
  SCHEDULED_TASK: {
    key: 'scheduled_tasks',
    label: 'Scheduled Tasks',
    subtext: 'Enables Scheduled Tasks module'
  },
  SINGLE_LOYALTY: {
    key: 'single_loyalty_program',
    label: 'Single Loyalty',
    subtext: 'Allow only 1 Loyalty program to be set up'
  },
  SOCIAL_MEDIA_INTEGRATION: {
    key: 'social_media_integration',
    label: 'Social Media Integration',
    subtext: 'Enables Social Media settings for Campaigns, Catalogues, and Rewards'
  },
  SSO_LOGIN_SUPPORT: {
    key: 'sso_login_support',
    label: 'SSO Login Support',
    subtext: 'Enables SSO login support'
  },
  STASH_VOUCHER_CODE_SETUP: {
    key: 'Stash_voucher_code_setup',
    label: 'Stash Voucher Code Setup',
    subtext: 'Enables stash voucher code setup in reward'
  },
  SURVEY: {
    key: 'survey',
    label: 'Campaign Type - Survey',
    subtext: 'Enables Campaign type - Survey'
  },
  SURVEY_CAMPAIGN_AUDIENCES: {
    key: 'survey_campaign_audiences',
    label: 'Survey Campaign Audiences',
    subtext: "Create Audiences based on survey campaign's answers"
  },
  TEAMS: {
    key: 'teams',
    label: 'Team-based campaigns',
    subtext: 'Enables teams in stamp and progress campaigns. ' + FEATURE_SUBTEXT.QA
  },
  TIME_SALE_FOR_REWARDS_SELLING_PRICE: {
    key: 'total_sale_for_reward_selling_price',
    label: 'Time Sale for Rewards Selling Price',
    subtext: 'Time Sale for Rewards Selling Price'
  },
  TRANSACTION_REVERSAL: {
    key: 'transaction_reversal',
    label: 'Transaction Reversal',
    subtext: 'Enables transaction reversal feature'
  },
  TRANSACTION_RULE_TRIGGER: {
    key: 'transaction_rule_trigger',
    label: 'Transaction Rule Trigger',
    subtext: 'Enables a transaction rule trigger option in rule'
  },
  TRANSACTION_SETTLEMENT_DATE_FILTER: {
    key: 'transaction_settlement_date_filter',
    label: 'Transaction Settlement Date Filter',
    subtext:
      'Enables transaction settlement date filter in Reports while generating Loyalty Breakdown Reports'
  },
  VOUCHER_CODE_MODS: {
    key: 'generate_system_voucher_codes',
    label: 'Voucher Code Modifications',
    subtext: 'Changes the limits of System Generated rewards. ' + FEATURE_SUBTEXT.IN_DEVELOPMENT
  },
  VOUCHER_CODE_PREFIX: {
    key: 'voucher_code_prefix',
    label: 'Voucher Code Prefix',
    subtext: 'Enables system generated voucher code format prefix'
  },
  MANUAL_CLAIM_RAFFLE_TICKET: {
    key: 'manual_claim_raffle_ticket',
    label: 'Raffle Campaigns - Tickets must be claimed manually',
    subtext: 'Enables manual claim raffle ticket'
  },
  NO_OUTCOME_IN_GAME_CAMPAIGN: {
    key: 'no_outcome_in_game_campaign',
    label: 'No outcome button in games',
    subtext: 'No outcome button in games'
  },
  RESET_POINTS_ON_DEACTIVATE_CUSTOMER: {
    key: 'reset_points_on_deactivate_customer',
    label: 'Reset Points On Deactivate Customer',
    subtext: 'Enables reset earn points on customer deactivated'
  }
} as const);

export type FeatureFlagKey = (typeof FEATURES)[keyof typeof FEATURES]['key'];

// Used for image upload validation
export const SUPPORTED_IMAGE_FORMATS = '.jpg, .jpeg, .png, .svg';

//Maker Checker Constants
export const APPROVE_REQUEST_SUCCESS_MESSAGE = 'Request has been approved';
export const DELETE_REQUEST_SUCCESS_MESSAGE = 'Request has been deleted';
export const DENY_REQUEST_SUCCESS_MESSAGE = 'Request has been denied';
export const CANCEL_REQUEST_SUCCESS_MESSAGE = 'Request has been cancelled';
export const MULTIPLE_APPROVE_REQUEST_SUCCESS_MESSAGE = 'Requests have been approved';

export const REQUEST_TYPE = {
  PUBLISHED: 'publish_draft',
  UPDATE: 'update',
  DEACTIVATE: 'deactivate',
  REACTIVATE: 'reactivate'
};

export const IMAGE_ATTRIBUTES = ['images', 'image_metadata_mappings', 'og_image'] as const;

export const RULE_DETAILS_ATTRIBUTES = ['name', 'labels'];

export const RULE_TRIGGER_ATTRIBUTES = [
  'rule_type',
  'event_name',
  'event_params',
  'trigger_limit',
  'account_limit',
  'conditions',
  'account_interval_limit',
  'account_interval_units',
  'account_interval_period',
  'rule_trigger_interval_max_available',
  'rule_trigger_interval_units',
  'rule_trigger_interval_period',
  'begins_at',
  'ends_at',
  'trigger_available'
];

export const RULE_OUTCOME_ATTRIBUTES = [
  'outcome_id',
  'outcome_type',
  'outcome_value',
  'outcome_expires_at',
  'outcome_options',
  'send_notification',
  'include_outcome_audience_ids',
  'exclude_outcome_audience_ids',
  'outcome_base',
  'action_name',
  'earn_type',
  'max_outcome_value'
];

export const ERROR_CODES = {
  41214: ERRORS.APPROVE_OWN_REQUEST
};

export const ERROR_MESSAGES = {
  "Event 'expire' cannot transition from 'expired'.": 'Reward is expired and cannot be approved.'
};

export const AUDIENCE_WHITELISTED_ATTRIBUTES = [
  'filters',
  'audience_ids',
  'exclude_audience_ids',
  'include_audience_ids'
] as const;
